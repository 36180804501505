import DinoDataGrid from "./DataGrid";
import {useEffect, useState} from "react";
import useCallDataApi from "../hooks/data";

const DataGridWrapper = ({rows, columns, name, onRowClick}) => {
    const {postData} = useCallDataApi('table-order')
    const [order, setOrder] = useState([])

    const reorderColumns = (orderedList) => {
        const allList = columns.map(item => item?.field)
        const restList = allList.filter(item => !orderedList.includes(item))
        const notExists = orderedList.filter(item => !allList.includes(item))
        const filteredOrderList = orderedList.filter(item => !notExists.includes(item))
        const newColumns = []
        for (let item of filteredOrderList) {
            newColumns.push(columns.find(i => i?.field === item))
        }
        for (let item of restList) {
            newColumns.push(columns.find(i => i?.field === item))
        }
        return newColumns
    }

    useEffect(() => {
        postData('get_order', {name: name}).then(r => {
            if (r && r?.length > 0) setOrder(r)
            else setOrder(columns.map(i => i?.field))
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <DinoDataGrid name={name} rows={rows} columns={reorderColumns(order)} onRowClick={onRowClick}/>
    // return <DinoDataGrid name={name} rows={rows} columns={columns} onRowClick={onRowClick}/>
}

export default DataGridWrapper