import {Box, Chip, IconButton, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import React from "react";

const QueryDisplay = ({query}) => {

    const displayExpression = (expression) => {
        const key = Object.keys(expression)[0]
        const val = Object.values(expression)[0]?.toString()
        const regex = /__(lte|gte|gt|lt|contains|endswith|isnull)?$/; // This regex matches the operator at the end
        const match = key.match(regex);
        if (match) {
            const extractedOperator = match[1] || '=';
            const parts = key.split(regex);
            const variableNames = parts[0]
            return <Stack direction='row' spacing={1}>
                <Chip label={variableNames}/>
                <Chip label={extractedOperator} variant='outlined'/>
                <Chip label={val}/>
            </Stack>
        } else {
            return <Stack direction='row' spacing={1}>
                <Chip label={key}/>
                <Chip label='=' variant='outlined'/>
                <Chip label={val}/>
            </Stack>
        }
    }

    const colorList = ['#812424', '#39721f', '#41487c', '#6b3779']

    const renderQuery = (query, path = []) => {
        const nestedQuery = query?.AND || query?.OR

        if (query === undefined) return

        if (!Object.keys(query).length) {
            // Kezdő állapot, {}
            return <div style={{padding: '10px', marginTop: '10px', border: '1px solid #616b75', borderRadius: '5px'}}></div>
        }

        if (nestedQuery) {
            const currentOperator = query?.AND ? 'AND' : 'OR'
            path = [...path, currentOperator]

            const filter = path.filter(i => typeof i === 'string');
            const color = colorList[filter.length % colorList.length]

            return <div style={{background: `${color}`, padding: '10px', marginTop: '10px', border: '1px solid #616b75', borderRadius: '5px'}}>
                <Stack direction='row' spacing={2}>
                    <p>{currentOperator}</p>
                </Stack>
                {nestedQuery.map((c, i) => <div key={`i_${path}_${i}`}>
                    {renderQuery(c, [...path, i])}
                </div>)}
            </div>
        } else {
            return displayExpression(query)
        }
    }
    return <Box>{renderQuery(query)}</Box>

}

export default QueryDisplay