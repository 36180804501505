import {Stack, TextField} from "@mui/material";

export default function ContactPersonData({reference, contactPerson}) {

    return <form ref={reference}>
        <Stack spacing={2}>
        <TextField required id="first_name" name="first_name" label="Keresztnév" defaultValue={contactPerson?.first_name || ''}/>
        <TextField required id="last_name" name="last_name" label="Vezetéknév" defaultValue={contactPerson?.last_name || ''}/>
        <TextField id="phone" name="phone" label="Telefonszám" defaultValue={contactPerson?.phone || ''}/>
        <TextField id="email" name="email" label="Email" type="email" defaultValue={contactPerson?.email || ''}/>
        </Stack>
    </form>;
}