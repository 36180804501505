import {InputLabel, MenuItem, Select} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import useCallDataApi from "../../hooks/data";


export default function DailyData({reference, connection}) {
    const [couriers, setCouriers] = useState([]);
    const [courier, setCourier] = useState("");

    const {getData: getCouriers} = useCallDataApi("couriers");

    const getAllCouriers = () => {
        getCouriers().then(c => setCouriers(c))
    }

    useEffect(() => {
        getAllCouriers()
    }, [])

    const handleConnectionChange = (event) => {
        setCourier(event.target.value)
    }

    return <form ref={reference}>
        <Stack>
            <FormControl sx={{m: 1, minWidth: 120}}>
                <InputLabel id="courier-select">Futár</InputLabel>
                <Select
                    labelId="courier-select"
                    label="Futár"
                    id="courier"
                    name="courier"
                    value={courier}
                    onChange={handleConnectionChange}
                >
                    {couriers.map(courier => (
                        <MenuItem key={courier.id} value={courier.id}>{courier.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    </form>;
}