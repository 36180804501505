import {
    Checkbox,
    FormControlLabel, InputAdornment,
    InputLabel, ListSubheader,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useMemo, useRef, useState} from "react";
import FormControl from "@mui/material/FormControl";
import useCallDataApi from "../../hooks/data";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import {Autocomplete} from "@mui/material";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import locale from 'date-fns/locale/en-GB'
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import TourGuideData from "../tourGuides/TourGuideData";
import {useSnackbar} from "notistack";
import TourOfficeData from "../tourOffices/TourOfficeData";
import {getFormData} from "../../utils/FormUtils";
import DinoDialog from "../../components/Dialog";


export default function OrderData({reference, order}) {
    const [orderStatus, setOrderStatus] = useState(order?.order_status || 'Confirmed')
    const [deliveryType, setDeliveryType] = useState(order?.delivery_type || 'Kézbe')
    const [deliveryFormat, setDeliveryFormat] = useState(order?.delivery_format || 'Backpack')
    const [returnType, setReturnType] = useState(order?.return_type || 'Kézbe')
    const [tourType, setTourType] = useState(order?.tour_type || 'Budapest')
    const [invoiceType, setInvoiceType] = useState(order?.invoice || 'Cash')
    const [colorType, setColorType] = useState(order?.color || 'Lila')
    const [currency, setCurrency] = useState(order?.currency || 'HUF')
    const [stock, setStock] = useState(0)
    const [deliveryTime, setDeliveryTime] = useState(order?.delivery_time)
    const [returnTime, setReturnTime] = useState(order?.return_time)
    const [isShort, setIsShort] = useState(order?.short_rent || false)


    const [tourGuides, setTourGuides] = useState([]);
    const [tourGuide, setTourGuide] = useState(order?.tour_guide || "");
    const [deliveryPeriod, setDeliveryPeriod] = useState(order?.delivery_period || "");
    const [returnPeriod, setReturnPeriod] = useState(order?.return_period || "");

    const [tourOffices, setTourOffices] = useState([]);
    const [tourOffice, setTourOffice] = useState(order?.tour_office || "");

    const [contactPersons, setContactPersons] = useState([]);
    const [contactPerson, setContactPerson] = useState(order?.contact_person || "");

    const [deliveryDate, setDeliveryDate] = useState(order?.delivery_date || new Date());
    const [returnDate, setReturnDate] = useState(order?.return_date || new Date());

    const {getData} = useCallDataApi("orders");
    const {createData: createTourGuide, getData: getTourGuideData} = useCallDataApi("tour-guides");
    const {createData: createTourOffice, getData: getTourOfficeData} = useCallDataApi("tour-offices");
    const {getData: getContactPersons} = useCallDataApi("contact-person");

    const [isTourGuideDialogOpen, toggleTourGuideDialog] = useState(false)
    const [isTourOfficeDialogOpen, toggleTourOfficeDialog] = useState(false)
    const [receivers, setReceivers] = useState([])
    const newTourGuideForm = useRef(null);
    const newTourOfficeForm = useRef(null);
    const {enqueueSnackbar} = useSnackbar()

    const containsText = (text, searchText) => {
        if (searchText === "") return true
        return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    };

    const [searchText, setSearchText] = useState("");

    const getReceivers = () => {
        getData("receivers").then(r => (setReceivers(r)))
    }

    const getTourOffices = () => {
        getTourOfficeData().then(allTourOffices => setTourOffices(allTourOffices))
    }

    const getAllContactPersons = () => {
        getContactPersons().then(contactPersons => {
            const forTourOffice = contactPersons.filter(c => c?.tour_office === tourOffice)
            setContactPersons(forTourOffice)
        })
    }

    const getTourGuides = () => {
        getTourGuideData().then(allTourGuides => setTourGuides(allTourGuides))
    }

    const getCurrentStock = () => {
        if (receivers !== undefined && colorType !== undefined) {
            setStock(receivers[colorType])
        } else {
            setStock(0)
        }
    }

    useEffect(() => {
        getTourOffices()
        getTourGuides()
        getReceivers()
    }, [])

    useEffect(getCurrentStock, [receivers, colorType])

    useEffect(() => {
        getAllContactPersons()
    }, [tourOffice])

    const handelOrderStatusChange = (event) => {
        setOrderStatus(event.target.value)
    }

    const handelContactPersonChange = (event) => {
        setContactPerson(event.target.value)
    }

    const handelDeliveryTypeChange = (event) => {
        setDeliveryType(event.target.value)
    }

    const handelReturnTypeChange = (event) => {
        setReturnType(event.target.value)
    }

    const handelTourTypeChange = (event) => {
        setTourType(event.target.value)
    }

    const handelInvoiceTypeChange = (event) => {
        setInvoiceType(event.target.value)
    }

    const handelTourGuideChange = (event) => {
        setTourGuide(event.target.value)
    }

    const handelDeliveryPeriodChange = (event) => {
        setDeliveryPeriod(event.target.value)
    }

    const handelReturnPeriodChange = (event) => {
        setReturnPeriod(event.target.value)
    }

    const handelTourOfficeChange = (event) => {
        setTourOffice(parseInt(event.target.value))
    }

    const handelColorTypeChange = (event) => {
        setColorType(event.target.value)
    }

    const handelCurrencyChange = (event) => {
        setCurrency(event.target.value)
    }

    const handelDeliveryFormatChange = (event) => {
        setDeliveryFormat(event.target.value)
    }

    const handleShortChange = () => {
        setIsShort((prev) => !prev)
    }

    const handleNewTourGuide = () => {
        const tourGuideData = getFormData(newTourGuideForm.current)
        createTourGuide(tourGuideData).then(newTourGuide => {
            setTourGuides(tourGuide => [...tourGuide, newTourGuide])
            enqueueSnackbar('Csoportkísérő mentve!', {variant: 'success'})
        })
    }

    const handleNewTourOffice = () => {
        const data = getFormData(newTourOfficeForm.current)
        createTourOffice(data).then(newTourOffice => {
            setTourOffices([...tourOffices, newTourOffice])
            enqueueSnackbar('Megrendelő mentve!', {variant: 'success'})
        })
    }

    const displayedTourguides = useMemo(
        () => tourGuides.filter((tourGuide) => containsText(tourGuide.first_name, searchText) || containsText(tourGuide.last_name, searchText)),
        [searchText, tourGuides]
    );
    const displayedContactPersons = useMemo(
        () => contactPersons.filter((contactPerson) => containsText(contactPerson.first_name, searchText) || containsText(contactPerson.last_name, searchText)),
        [searchText, contactPersons]
    );
    const displayedTourOffices = useMemo(
        () => tourOffices.filter((tourOffice) => containsText(tourOffice.name, searchText)),
        [searchText, tourOffices]
    );

    return <>
        <form ref={reference}>
            <Stack spacing={2}>
                <Typography variant="h6" gutterBottom>Általános infó</Typography>
                <TextField multiline rows={3} id="comment" name="comment" label="Megjegyzés" defaultValue={order?.comment || ''}/>
                <TextField multiline rows={3} id="internal_comment" name="internal_comment" label="Belsős megjegyzés"
                           defaultValue={order?.internal_comment || ''}/>
                <FormControl>
                    <InputLabel id="order-statuses">Állapot</InputLabel>
                    <Select
                        labelId="order-statuses"
                        label="Állapot"
                        id="order_status"
                        name="order_status"
                        value={orderStatus}
                        onChange={handelOrderStatusChange}
                    >
                        <MenuItem value={"Confirmed"}>Confirmed</MenuItem>
                        <MenuItem value={"DN Printed"}>DN Printed</MenuItem>
                        <MenuItem value={"Delivered"}>Delivered</MenuItem>
                        <MenuItem value={"Courier ordered"}>Courier ordered</MenuItem>
                        <MenuItem value={"Returning"}>Returning</MenuItem>
                        <MenuItem value={"Returned"}>Returned</MenuItem>
                        <MenuItem value={"Cancelled"}>Cancelled</MenuItem>
                        <MenuItem value={"ON THE WAY OUT"}>ON THE WAY OUT</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{m: 1, minWidth: 120}}>
                    <InputLabel id="tour-types">Program</InputLabel>
                    <Select
                        labelId="tour-types"
                        label="Program"
                        id="tour_type"
                        name="tour_type"
                        value={tourType}
                        onChange={handelTourTypeChange}
                    >
                        <MenuItem value={"Budapest"}>Budapest</MenuItem>
                        <MenuItem value={"Gyárlátogatás"}>Gyárlátogatás</MenuItem>
                        <MenuItem value={"Külföld"}>Külföld</MenuItem>
                        <MenuItem value={"Ski"}>Ski</MenuItem>
                        <MenuItem value={"Hajóutak"}>Hajóutak</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{m: 1, minWidth: 120}}>
                    <InputLabel id="tour-offices">Megrendelő</InputLabel>
                    <Select
                        labelId="tour-offices"
                        label="Megrendelő"
                        id="tour_office"
                        name="tour_office"
                        value={tourOffice}
                        onChange={handelTourOfficeChange}
                    >
                        <ListSubheader>
                            <TextField
                                size="small"
                                // Autofocus on textfield
                                autoFocus
                                placeholder="Type to search..."
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(e) => setSearchText(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key !== "Escape") {
                                        // Prevents autoselecting item while typing (default Select behaviour)
                                        e.stopPropagation();
                                    }
                                }}
                            />
                        </ListSubheader>
                        <MenuItem value={"None"}>None</MenuItem>
                        {displayedTourOffices.map(tourOffice => <MenuItem key={tourOffice.id}
                                                                          value={tourOffice.id}>{tourOffice.name}</MenuItem>)}
                    </Select>
                    <Button style={{'marginTop': '10px'}} onClick={() => {
                        toggleTourOfficeDialog(true)
                    }} variant="outlined"
                            color="success"><AddIcon/><Typography>Megrendelő</Typography></Button>
                </FormControl>
                {contactPersons.length > 0 && <FormControl sx={{m: 1, minWidth: 120}}>
                    <InputLabel id="contact-persons">Kapcsolattartó</InputLabel>
                    <Select
                        labelId="contact-persons"
                        label="Kapcsolattartó"
                        id="contact_person"
                        name="contact_person"
                        value={contactPerson}
                        onChange={handelContactPersonChange}
                    >
                        <ListSubheader>
                            <TextField
                                size="small"
                                // Autofocus on textfield
                                autoFocus
                                placeholder="Type to search..."
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(e) => setSearchText(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key !== "Escape") {
                                        // Prevents autoselecting item while typing (default Select behaviour)
                                        e.stopPropagation();
                                    }
                                }}
                            />
                        </ListSubheader>
                        <MenuItem value={"None"}>None</MenuItem>
                        {displayedContactPersons.map(contactPerson => <MenuItem key={contactPerson.id}
                                                                                value={contactPerson.id}>{`${contactPerson.first_name} ${contactPerson.last_name} ${contactPerson?.phone}`}</MenuItem>)}
                    </Select>
                </FormControl>}
                <TextField id="tour_code" name="tour_code" label="Megnevezés" defaultValue={order?.tour_code || ''}/>

                <Typography variant="h6" gutterBottom>Kiszállítás adatok</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                    <DatePicker
                        label="Oda dátum"
                        value={deliveryDate}
                        inputFormat="yyyy-MM-dd"
                        mask="____-__-__"
                        onChange={(newValue) => {
                            setDeliveryDate(newValue);
                            if (returnDate <= newValue) setReturnDate(newValue);

                        }}
                        InputAdornmentProps={{ position: 'start' }}
                        renderInput={(params) => <TextField id="delivery_date" InputAdornmentProps={{ position: 'start' }} name="delivery_date" {...params} />}
                    />
                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                    <DatePicker
                        label="Vissza dátum"
                        value={returnDate}
                        inputFormat="yyyy-MM-dd"
                        mask="____-__-__"
                        onChange={(newValue) => {
                            setReturnDate(newValue);
                        }}
                        InputAdornmentProps={{ position: 'start' }}
                        renderInput={(params) => <TextField id="return_date" InputAdornmentProps={{ position: 'start' }} name="return_date" {...params} />}
                    />
                </LocalizationProvider>
                <Stack direction='row' spacing={2}>
                    <TextField type="number" id="order_rx" name="order_rx" label="Order rx"
                               defaultValue={order?.order_rx || ''} sx={{width: '100%'}}/>
                    <TextField type="number" id="order_tx" name="order_tx" label="Order tx"
                               defaultValue={order?.order_tx || ''} sx={{width: '100%'}}/>
                    <TextField type="number" id="sent_rx" name="sent_rx" label="Sent rx"
                               defaultValue={order?.sent_rx || ''} sx={{width: '100%'}}/>
                    <TextField type="number" id="sent_tx" name="sent_tx" label="Sent tx"
                               defaultValue={order?.sent_tx || ''} sx={{width: '100%'}}/>
                </Stack>



                <FormControl sx={{m: 1, minWidth: 120}}>
                    <InputLabel id="color-type">Szín</InputLabel>
                    <Select
                        labelId="color-type"
                        label="Color"
                        id="color"
                        name="color"
                        value={colorType}
                        onChange={handelColorTypeChange}
                    >
                        <MenuItem value={"Lila"}>Lila</MenuItem>
                        <MenuItem value={"Narancssárga"}>Narancssárga</MenuItem>
                        <MenuItem value={"Oliva"}>Oliva</MenuItem>
                        <MenuItem value={"Fehér"}>Fehér</MenuItem>
                        <MenuItem value={"Piros"}>Piros</MenuItem>
                        <MenuItem value={"Zöld"}>Zöld</MenuItem>
                        <MenuItem value={"Szürke"}>Szürke</MenuItem>
                        <MenuItem value={"Szürke E"}>Szürke E</MenuItem>
                        <MenuItem value={"VOX"}>VOX</MenuItem>
                    </Select>
                </FormControl>
                <Typography variant="p">Jelenleg {stock} darab {colorType} színű vevő van raktáron.</Typography>

                <TextField id="delivery_address" name="delivery_address" label="Szállítási cím"
                           defaultValue={order?.delivery_address || ''}/>

                <Stack direction='row' spacing={1}>
                    <TextField
                        id="delivery_time"
                        name="nullable-delivery_time"
                        label="Szállítási idő"
                        type="time"
                        // value={deliveryTime}
                        // onChange={e => setDeliveryTime(e.target.value === '' ? null : e.target.value)}
                        defaultValue={order?.delivery_time}
                        sx={{width: '100%'}}
                    />

                    <FormControl sx={{width: '100%'}}>
                        <InputLabel id="deivery_period_selector">Oda mikor</InputLabel>
                        <Select
                            labelId="deivery_period_selector"
                            label="Mikor"
                            id="delivery_period"
                            name="delivery_period"
                            value={deliveryPeriod}
                            onChange={handelDeliveryPeriodChange}
                        >
                            <MenuItem value={"-"}>None</MenuItem>
                            <MenuItem value={"EN"}>EN</MenuItem>
                            <MenuItem value={"DE"}>DE</MenuItem>
                            <MenuItem value={"DU"}>DU</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField sx={{width: '100%'}} id="days_before" type="number" name="days_before" label="Előtte"
                               defaultValue={order?.days_before || ''}/>
                </Stack>

                <TextField id="return_address" name="return_address" label="Vissza cím"
                           defaultValue={order?.return_address || ''}/>


                <Stack direction='row' spacing={1}>
                    <TextField
                        id="return_time"
                        name="nullable-return_time"
                        label="Vissza idő"
                        type="time"
                        // value={returnTime}
                        // onChange={e => setReturnTime(e.target.value === '' ? null : e.target.value)}
                        defaultValue={order?.return_time}
                        sx={{width: '100%'}}
                    />

                    <FormControl sx={{width: '100%'}}>
                        <InputLabel id="return_period_selector">Vissza mikor</InputLabel>
                        <Select
                            labelId="return_period_selector"
                            label="Vissza mikor"
                            id="return_period"
                            name="return_period"
                            value={returnPeriod}
                            onChange={handelReturnPeriodChange}
                        >
                            <MenuItem value={"-"}>None</MenuItem>
                            <MenuItem value={"EN"}>EN</MenuItem>
                            <MenuItem value={"DE"}>DE</MenuItem>
                            <MenuItem value={"DU"}>DU</MenuItem>
                        </Select>
                    </FormControl>

                    <TextField sx={{width: '100%'}} id="days_after" type="number" name="days_after" label="Utána"
                               defaultValue={order?.days_after || ''}/>
                </Stack>
                <FormControl sx={{m: 1, minWidth: 120}}>
                    <InputLabel id="tour-guides">Csoportkísérő</InputLabel>
                    <Select
                        labelId="tour-guides"
                        label="Csoportkísérő"
                        id="tour_guide"
                        name="tour_guide"
                        value={tourGuide}
                        onChange={handelTourGuideChange}
                    >
                        <ListSubheader>
                            <TextField
                                size="small"
                                // Autofocus on textfield
                                autoFocus
                                placeholder="Type to search..."
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon/>
                                        </InputAdornment>
                                    )
                                }}
                                onChange={(e) => setSearchText(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key !== "Escape") {
                                        // Prevents autoselecting item while typing (default Select behaviour)
                                        e.stopPropagation();
                                    }
                                }}
                            />
                        </ListSubheader>
                        <MenuItem value={"None"}>None</MenuItem>
                        {displayedTourguides.map(tourGuide => {
                            return <MenuItem key={tourGuide.id}
                                             value={tourGuide.id}>{`${tourGuide.first_name} ${tourGuide.last_name} ${tourGuide?.phone}`}</MenuItem>
                        })}
                    </Select>
                    <Button style={{'marginTop': '10px'}} onClick={() => {
                        toggleTourGuideDialog(true)
                    }} variant="outlined"
                            color="success"><AddIcon/><Typography>Csoportkísérő</Typography></Button>
                </FormControl>
                <input type="hidden" value={isShort} name='logicalfield-short_rent'/>
                <FormControl>
                    <FormControlLabel
                        label="Rövid bérlés?"
                        control={
                            <Checkbox
                                id="short_rent"
                                checked={isShort}
                                value={isShort}
                                defaultValue={isShort}
                                onClick={handleShortChange}
                            />
                        }
                    />
                </FormControl>

                <Stack direction='row' spacing={2}>
                    <FormControl sx={{width: '100%'}}>
                        <InputLabel id="delivery-format">Kiszállítási formátum</InputLabel>
                        <Select
                            labelId="delivery-format"
                            label="Delivery Format"
                            id="delivery_format"
                            name="delivery_format"
                            value={deliveryFormat}
                            onChange={handelDeliveryFormatChange}
                        >
                            <MenuItem value={"Backpack"}>Backpack</MenuItem>
                            <MenuItem value={"Case"}>Case</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{width: '100%'}}>
                        <InputLabel id="delivery-types">Szállítási mód</InputLabel>
                        <Select
                            labelId="delivery-types"
                            label="Szállítási mód"
                            id="delivery_type"
                            name="delivery_type"
                            value={deliveryType}
                            onChange={handelDeliveryTypeChange}
                        >
                            <MenuItem value={"DHL Standard"}>DHL Standard</MenuItem>
                            <MenuItem value={"DHL Express"}>DHL Express</MenuItem>
                            <MenuItem value={"GLS"}>GLS</MenuItem>
                            <MenuItem value={"TGS Iroda"}>TGS Iroda</MenuItem>
                            <MenuItem value={"Kézbe"}>Kézbe</MenuItem>
                        </Select>
                    </FormControl>

                    <FormControl sx={{width: '100%'}}>
                        <InputLabel id="return-types">Visszaszállítás módja</InputLabel>
                        <Select
                            labelId="return-types"
                            label="Vissza szállítási mód"
                            id="return_type"
                            name="return_type"
                            value={returnType}
                            onChange={handelReturnTypeChange}
                        >
                            <MenuItem value={"DHL Standard"}>DHL Standard</MenuItem>
                            <MenuItem value={"DHL Express"}>DHL Express</MenuItem>
                            <MenuItem value={"GLS"}>GLS</MenuItem>
                            <MenuItem value={"TGS Iroda"}>TGS Iroda</MenuItem>
                            <MenuItem value={"Kézbe"}>Kézbe</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>

                <TextField id="DHL_delivery_number" name="DHL_delivery_number" label="Oda fuvarlevélszám"
                           defaultValue={order?.DHL_delivery_number || ''}/>

                <TextField id="DHL_order" name="DHL_order" label="Oda felvételazonosító"
                           defaultValue={order?.DHL_order || ''}/>

                <TextField id="DHL_return_number" name="DHL_return_number" label="Vissza fuvarlevélszám"
                           defaultValue={order?.DHL_return_number || ''}/>

                <TextField id="DHL_order_back" name="DHL_order_back" label="Vissza felvételazonosító"
                           defaultValue={order?.DHL_order_back || ''}/>


                <Typography variant="h6" gutterBottom>Visszavétel Check</Typography>

                <TextField type="number" id="returned_rx" name="returned_rx" label="Returned rx"
                           defaultValue={order?.returned_rx || ''}/>

                <TextField type="number" id="returned_tx" name="returned_tx" label="Returned tx"
                           defaultValue={order?.returned_tx || ''}/>


                <Typography variant="h6" gutterBottom>Pénzügyi adatok</Typography>
                <TextField type="number" InputProps={{inputProps: {min: 0}}} id="extra_delivery_price"
                           name="extra_delivery_price" label="Extra kiszállítási ár"
                           defaultValue={order?.extra_delivery_price || ''}/>
                <TextField type="number" InputProps={{inputProps: {min: 0}}} id="agreed_price" name="agreed_price"
                           label="Ár" defaultValue={order?.agreed_price || ''}/>
                <FormControl sx={{m: 1, minWidth: 120}}>
                    <InputLabel id="invoice-type">Számla típusa</InputLabel>
                    <Select
                        labelId="invoice-type"
                        label="Számla típusa"
                        id="invoice"
                        name="invoice"
                        value={invoiceType}
                        onChange={handelInvoiceTypeChange}
                    >
                        <MenuItem value={"Invoice bank transfer"}>Invoice bank transfer</MenuItem>
                        <MenuItem value={"Invoice cash"}>Invoice cash</MenuItem>
                        <MenuItem value={"Cash"}>Cash</MenuItem>
                        <MenuItem value={"Invoice credit card"}>Invoice credit card</MenuItem>
                        <MenuItem value={"Compansation"}>Compansation</MenuItem>
                    </Select>
                </FormControl>
                <TextField id="invoice_number" name="invoice_number" label="Számlasorszám"
                           defaultValue={order?.invoice_number || ''}/>

                <FormControl sx={{m: 1, minWidth: 120}}>
                    <InputLabel id="currency-types">Valuta</InputLabel>
                    <Select
                        labelId="currency-types"
                        label="Valuta"
                        id="currency"
                        name="currency"
                        value={currency}
                        onChange={handelCurrencyChange}
                    >
                        <MenuItem value={"HUF"}>HUF</MenuItem>
                        <MenuItem value={"EUR"}>EUR</MenuItem>
                    </Select>
                </FormControl>

            </Stack>
        </form>

        <DinoDialog
            title="Új csoportkísérő"
            open={isTourGuideDialogOpen}
            handleClose={() => toggleTourGuideDialog(false)}
            actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                <Button size='large' variant='outlined' onClick={() => toggleTourGuideDialog(false)}>Mégsem</Button>
                <Button size='large' variant='outlined' color='success' onClick={() => {
                    handleNewTourGuide()
                    toggleTourGuideDialog(false)
                }}>Hozzáadás</Button>
            </Stack>}>
            <TourGuideData reference={newTourGuideForm}/>
        </DinoDialog>

        <DinoDialog
            title="Új megrendelő"
            open={isTourOfficeDialogOpen}
            handleClose={() => toggleTourOfficeDialog(false)}
            actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                <Button size='large' variant='outlined' onClick={() => toggleTourOfficeDialog(false)}>Mégsem</Button>
                <Button size='large' variant='outlined' color='success' onClick={() => {
                    handleNewTourOffice()
                    toggleTourOfficeDialog(false)
                }}>Hozzáadás</Button>
            </Stack>}>
            <TourOfficeData reference={newTourOfficeForm}/>
        </DinoDialog>
    </>;
}