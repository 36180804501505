import {Checkbox, FormControlLabel, TextField} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useEffect, useState} from "react";
import useCallDataApi from "../../hooks/data";
import Loading from "../../components/Loading";


export default function WaybillData({reference, waybill}) {
    const {postData: postWaybills} = useCallDataApi('waybills')
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({
        branded_lanyards: false,
        extra_batteries: false,
        microphone: 2,
        am1_earphone: 0,
        neck_loop: 0
    })

    useEffect(() => {
        if (waybill) {
            postWaybills('get_by_order', {order_id: waybill})
                .then(d => setData(d))
                .finally(() => setLoading(false))
        } else {
            setLoading(false)
        }
    }, [])


    return <form ref={reference}>
        <Stack spacing={2} marginTop={1}>
            <TextField onChange={e => setData(p => ({...p, microphone: e.target.value}))} id="microphone" name="microphone" type="number" label="Mikrofon" InputProps={{ inputProps: { min: 0 } }} value={data?.microphone}/>
            <TextField onChange={e => setData(p => ({...p, am1_earphone: e.target.value}))} id="am1_earphone" name="am1_earphone" type="number" label="AM1 Fülhallgató" InputProps={{ inputProps: { min: 0 } }} value={data?.am1_earphone}/>
            <TextField onChange={e => setData(p => ({...p, neck_loop: e.target.value}))} id="neck_loop" name="neck_loop" type="number" label="Nyakbaakasztó" InputProps={{ inputProps: { min: 0 } }} value={data?.neck_loop}/>
            <input type="hidden" name="logicalfield-branded_lanyards" value={data?.branded_lanyards}/>
            <FormControlLabel
                label="Egyedi nyakbaakasztók?"
                control={
                    <Checkbox
                        id="branded_lanyards"
                        checked={data?.branded_lanyards}
                        onClick={() => setData(p => ({...p, branded_lanyards: !p?.branded_lanyards}))}
                    />
                }
            />
            <input type="hidden" name="logicalfield-extra_batteries" value={data?.extra_batteries}/>
            <FormControlLabel
                label="Extra Elemek?"
                control={
                    <Checkbox
                        id="extra_batteries"
                        checked={data?.extra_batteries}
                        onClick={() => setData(p => ({...p, extra_batteries: !p?.extra_batteries}))}
                    />
                }
            />

        </Stack>
        <Loading isLoading={loading}/>
    </form>
}