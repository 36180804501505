import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Stack, TextField} from "@mui/material";
import BasicSpeedDial from "../../components/BasicSpeedDial";
import {GridActionsCellItem} from "@mui/x-data-grid";
import TrackingDialog from "../../components/TrackingDialog";
import OrderData from "./OrderData";
import ConnectionData from "./ConnectionData";
import WaybillData from "./WaybillData";
import AuthContext from "../../context/AuthContext";
import useCallDataApi from "../../hooks/data";
import {useSnackbar} from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from "@mui/icons-material/Delete";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import VignetteIcon from '@mui/icons-material/Vignette';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import {getFormData} from "../../utils/FormUtils";
import PersistentDrawerLeft from "../../components/NewDrawer";
import Loading from "../../components/Loading";
import OrderDetail from "./OrderDetail";
import DinoDialog from "../../components/Dialog";
import DataGridWrapper from "../../components/DataGridWrapper";
import {createMap, createMapKey} from "../../utils/NumberUtils";
import StickerData from "./StickerData";


const Orders = () => {
    const {getData: fetchOrders, getFile, createData, updateData, deleteData, postData} = useCallDataApi("orders");

    const {getData: getConnections, updateData: editConnection} = useCallDataApi("courier-order")
    const {getData: getCouriers} = useCallDataApi("couriers")

    const {updateData: editWaybill} = useCallDataApi("waybills");
    const {updateData: editSticker} = useCallDataApi("sticker");
    const {updateData: editDoubleSticker} = useCallDataApi("double-sticker");

    const {getData: getTourOffices} = useCallDataApi("tour-offices");
    const {getData: getTourGuides} = useCallDataApi("tour-guides");

    const [orders, setOrders] = useState([]);
    const [tourOffices, setTourOffices] = useState({list: [], obj: {}});
    const [tourGuides, setTourGuides] = useState({list: [], obj: {}});
    const [couriers, setCouriers] = useState([]);
    const [isOrderDialogOpen, toggleOrderDialog] = useState(false);
    const [isEditOrderDialogOpen, toggleEditOrderDialog] = useState(false);
    const [isEditWaybillDialogOpen, toggleEditWaybillDialog] = useState(false);
    const [isEditStickerDialogOpen, toggleEditStickerDialog] = useState(false);
    const [isDeleteOrderDialogOpen, toggleDeleteOrderDialog] = useState(false);
    const [isDetailOrderDialogOpen, toggleDetailOrderDialog] = useState(false);
    const [isCloseOrderDialogOpen, toggleCloseOrderDialog] = useState(false);
    const [isOpenOrderDialogOpen, toggleOpenOrderDialog] = useState(false);
    const [isCourierOrderDialogOpen, toggleCourierOrderDialog] = useState(false);
    const [isTrackingDialogOpen, toggleTrackingDialog] = useState(false);
    const [isValidateDialogOpen, toggleValidateDialog] = useState(false);
    const [current, setCurrent] = useState(0);
    const [connections, setConnections] = useState({list: [], obj: {}})
    const [tracking, setTracking] = useState([])
    const [loading, setLoading] = useState(true)

    const newOrderForm = useRef(null);
    const editOrderForm = useRef(null);
    const editWaybillForm = useRef(null);
    const connectionForm = useRef(null);
    const reasonForm = useRef(null);
    const editStickerForm = useRef(null);

    const {permissions} = useContext(AuthContext);
    const {enqueueSnackbar} = useSnackbar();


    const getTracking = (id) => fetchOrders(`${id}/track`).then(t => setTracking(t))

    const getOrder = (id) => orders.find(o => (o?.id === id))

    const newOrder = (orderData) => {
        createData(orderData).then(
            newOrder => {
                setOrders(orders => [newOrder, ...orders])
                enqueueSnackbar('Megrendelés elmentve!', {variant: 'success'})
            }
        )
    }

    const editOrder = (orderData) => {
        updateData(current, orderData).then(editedOrder => {
            const editedOrderList = orders.map(order => {
                if (current === order.id) return {...order, ...editedOrder}
                return order;
            });
            setOrders(editedOrderList)
            enqueueSnackbar('Megrendelés szerkesztve!', {variant: 'success'})
        })
    }

    const deleteOrder = () => {
        deleteData(current).then(() => {
            setOrders(orders.filter(order => (order.id !== current)))
            enqueueSnackbar('Törölve!', {variant: 'success'})
        })
    }

    const changeWaybill = (id, data) => {
        editWaybill(id, data).then(() => enqueueSnackbar('Szerkesztve!', {variant: 'success'}))
    }

    // Open or close the given order
    const toggle = (id, reason) => {
        let isClosed = false
        postData(`${id}/toggle`, {reason: reason}).then(editedOrder => {
            isClosed = editedOrder.closed
            const editedOrderList = orders.map(order => {
                if (current === order.id) return {...order, ...editedOrder}
                return order;
            });
            setOrders(editedOrderList)
            enqueueSnackbar((isClosed ? "Lezárva!" : "Kinyitva!"), {variant: 'success'})
        })
    }

    const invalidate = (id) => {
        fetchOrders(`${id}/invalidate`).then(() => {
            const editedOrderList = orders.filter(order => (current !== order.id))
            setOrders(editedOrderList)
            enqueueSnackbar("Érvénytelenítve!", {variant: 'success'})
        })
    }

    // Change the courier for the delivery or the return | input: {order: current, ...formData}
    const changeConnection = (data) => {
        const id = connections.obj[current].id;
        editConnection(id, data).then(editedConnection => {
            const editedConnections = connections.list.map(connection => {
                if (id === connection.id) return {...connection, ...editedConnection}
                return connection;
            });
            setConnections({list: editedConnections, obj: {...connections.obj, [current]: editedConnection}})
            setOrders(orders)
            enqueueSnackbar('Szerkesztve!', {variant: 'success'})
        })
    }

    useEffect(() => {
        const getData = async () => {
            const [o, c, t, g, co] = await Promise.all([
                fetchOrders(),
                getConnections(),
                getTourOffices(),
                getTourGuides(),
                getCouriers()
            ])
            if (o) setOrders(o)
            if (c) setConnections({list: c, obj: createMapKey(c, 'order')})
            if (t) setTourOffices({list: t, obj: createMap(t)})
            if (g) setTourGuides({list: g, obj: createMap(g)})
            if (co) setCouriers(co)
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleNewOrder = () => {
        const formData = getFormData(newOrderForm.current)
        newOrder(formData)
    }

    const handleEditOrder = () => {
        const formData = getFormData(editOrderForm.current)
        editOrder(formData)
    }

    const handleEditWaybill = () => {
        const formData = getFormData(editWaybillForm.current)
        changeWaybill('set_by_order', {order_id: current, waybill_data: formData})
    }

    const formatTimeWithoutSeconds = (time) => {
        const parts = time.split(':');
        const formattedTime = parts.slice(0, 2).join(':');
        return formattedTime;
    }

    const copyOrderData = (order) => {
        const deliveryMap = {
            EN: 'Egész nap',
            DE: 'Dél előtt',
            DU: 'Dél után'
        }
        const deliveryMethodMap = {
            Kézbe: 'Futár',
        }
        const formattedText = `Kedves ! 
Köszönjük a megrendelést, melyet az alábbi adatokkal visszaigazolunk:

Megrendelés száma: ${order?.order_number || 'Egyeztetés szükséges'}
Bérlés időtartama: ${order?.days} nap (${order?.delivery_date} - ${order?.return_date})
Megrendelt készülékszám: Vevő: ${order?.order_rx || 'Egyeztetés szükséges'} db, Adó: ${order?.order_tx || 'Egyeztetés szükséges'} db (+ tartalékok)

Kiszállítás dátuma: ${order?.actual_delivery_date || 'Egyeztetés szükséges'}
Kiszállítás időpontja: ${order?.delivery_time ? formatTimeWithoutSeconds(order?.delivery_time) : deliveryMap[order?.delivery_period]  || 'Egyeztetés szükséges'}
Kiszállítás címe: ${order?.delivery_address || 'Egyeztetés szükséges'}

Visszaszállítás dátuma: ${order?.actual_return_date || 'Egyeztetés szükséges'}
Visszaszállítás időpontja: ${order?.return_time ? formatTimeWithoutSeconds(order?.return_time) : deliveryMap[order?.return_period] || 'Egyeztetés szükséges'}
Visszaszállítás címe: ${order?.return_address || 'Egyeztetés szükséges'}

Szállítás módja: ${deliveryMethodMap[order?.delivery_type] || order?.delivery_type}`;
        navigator.clipboard.writeText(formattedText)
    }


    const handleEditStickers = () => {
        setLoading(true)
        let formData = getFormData(editStickerForm.current)
        Promise.all([
            editSticker('set_by_order', {order_id: current, data: formData}),
            editDoubleSticker('set_by_order', {order_id: current, data: formData})
        ])
            .then(() => enqueueSnackbar('Matrica szerkesztve!', {variant: 'success'}))
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
    }

    const handleSetCourier = () => {
        let formData = getFormData(connectionForm.current)
        changeConnection({order: current, ...formData})
    }

    const columns = [
        {
            field: "manage",
            headerName: "Manage",
            sortable: false,
            type: "actions",
            getActions: (params) => {
                let menu = []
                if (permissions.includes('view_order') && !params.row["closed"]) menu.push(<GridActionsCellItem
                    icon={<ContentCopyRoundedIcon/>}
                    label="Rendelés adatainak másolása"
                    onClick={() => {
                        const order = orders.find(o => o.id === params.row.id);
                        copyOrderData(order)
                        enqueueSnackbar('Másolva!', {variant: 'success'})
                    }}
                    showInMenu={true}/>)
                if (permissions.includes('delete_order') && !params.row['closed']) menu.push(<GridActionsCellItem
                    icon={<DeleteIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleDeleteOrderDialog(true)
                    }}
                    label="Törlés"
                    showInMenu={true}
                />)
                if (permissions.includes('change_order') && !params.row["closed"]) menu.push(<GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Szerkesztés"
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleEditOrderDialog(true)
                    }}
                    showInMenu={true}/>)
                if (permissions.includes('change_waybill') && !params.row["closed"]) menu.push(<GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Szállítólevél szerkesztése"
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleEditWaybillDialog(true)
                    }}
                    showInMenu={true}/>)
                if (permissions.includes('change_sticker') && !params.row["closed"]) menu.push(<GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Matrica szerkesztése"
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleEditStickerDialog(true)
                    }}
                    showInMenu={true}/>)
                if (permissions.includes('view_waybill')) menu.push(<GridActionsCellItem
                    icon={<LocalShippingIcon/>}
                    label="Szállítólevél"
                    onClick={() => {
                        getFile(`${params.row.id}/waybill`, 'pdf', params.row['order_number'] + '-waybill').then(r => console.log(r))
                    }}
                    showInMenu={true}/>)
                if (permissions.includes('view_sticker')) menu.push(<GridActionsCellItem
                    icon={<VignetteIcon/>}
                    label="Matrica"
                    onClick={() => {
                        getFile(`${params.row.id}/sticker`, 'pdf', params.row['order_number'] + '-sticker').then(r => console.log(r))
                    }}
                    showInMenu={true}/>)
                if (permissions.includes('view_doublesticker')) menu.push(<GridActionsCellItem
                    icon={<VignetteIcon/>}
                    label="Dupla matrica"
                    onClick={() => {
                        getFile(`${params.row.id}/double_sticker`, 'pdf', params.row['order_number'] + '-double-sticker').then(r => console.log(r))
                    }}
                    showInMenu={true}/>)
                if (permissions.includes('change_ordercourierconnection') && !params.row["closed"]) menu.push(
                    <GridActionsCellItem
                        icon={<ManageAccountsIcon/>}
                        onClick={() => {
                            setCurrent(params.row.id)
                            toggleCourierOrderDialog(true)
                        }}
                        label="Futár beállítása"
                        showInMenu={true}
                    />)
                if (permissions.includes('can_track_order')) menu.push(<GridActionsCellItem
                    icon={<AccountTreeIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        getTracking(params.row["id"])
                        toggleTrackingDialog(true)
                    }}
                    label="Nyomonkövetés"
                    showInMenu={true}
                />)
                if (permissions.includes('can_open_order') && permissions.includes('can_close_order')) menu.push(
                    <GridActionsCellItem
                        icon={params.row["closed"] ? <LockOpenIcon/> : <LockIcon/>}
                        onClick={() => {
                            setCurrent(params.row["id"])
                            params.row["closed"] ? toggleOpenOrderDialog(true) : toggleCloseOrderDialog(true)
                        }}
                        label={params.row["closed"] ? "Kinyitás" : "Lezárás"}
                        showInMenu={true}
                    />)
                if (permissions.includes('can_validate_order')) menu.push(<GridActionsCellItem
                    icon={<CloseIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleValidateDialog(true)
                    }}
                    label="Érvénytelenítés"
                    showInMenu={true}
                />)

                return menu
            },
        },
        {field: "order_number", headerName: "Rendelés szám", width: 140},
        {field: "comment", headerName: "Megjegyzés", width: 130},
        {field: "internal_comment", headerName: "Belsős megjegyzés", width: 130},
        {field: "order_status", headerName: "Állapot", width: 130},
        {field: "tour_type", headerName: "Program", width: 110},
        {field: "tour_office", headerName: "Megrendelő", width: 110, valueGetter: (params) => {
                const to = tourOffices.obj[params.row.tour_office]
                if (to) return to?.name || to?.short_name
                return ''
            }},
        {field: "tour_code", headerName: "Megnevezés", width: 110},
        {field: "tour_guide", headerName: "Csoportkísérő", width: 110, valueGetter: (params) => {
                const tg = tourGuides.obj[params.row.tour_guide]
                if (tg) return `${tg?.last_name} ${tg?.first_name}`
                return ''
            }},
        {field: "delivery_date", type: "date", headerName: "Kiszállítás dátum", width: 200},
        {
            field: "delivery_time",
            type: "string",
            headerName: "Kiszállítás idő",
            width: 200,
            renderCell: (params) => (params.row['delivery_time']?.slice(0, 5))
        },
        {field: "delivery_period", type: "string", headerName: "Mikor", width: 50},
        {field: "days_before", headerName: "Előtte", width: 110},
        {field: "delivery_type", headerName: "Kiszállítás típusa", width: 110},
        {field: "delivery_address", headerName: "Kiszállítási cím", width: 250},
        {field: "delivery_format", headerName: "Delivery format", width: 110},
        {field: "return_date", type: "date", headerName: "Vissza dátum", width: 200},
        {
            field: "return_time",
            type: "string",
            headerName: "Vissza idő",
            width: 200,
            renderCell: (params) => (params.row['return_time']?.slice(0, 5))
        },
        {field: "days_after", headerName: "Utána", width: 110},
        {field: "return_type", headerName: "Visszaszállítás típusa", width: 110},
        {field: "return_address", headerName: "Vissza cím", width: 250},
        {field: "returned_rx", headerName: "Visszérkezett RX", width: 110},
        {field: "returned_tx", headerName: "Visszaérkezett TX", width: 110},
        {
            field: "color",
            headerName: "Szín",
            width: 110,
            cellClassName: (params) => ('color-' + params.row['color'])
        },
        {field: "order_rx", headerName: "Rendelt RX", width: 110},
        {field: "order_tx", headerName: "Rendelt TX", width: 110},
        {field: "sent_rx", headerName: "Elküldött RX", width: 110},
        {field: "sent_tx", headerName: "Elküldött TX", width: 110},
        {field: "invoice", headerName: "Számla típusa", width: 110},
        {field: "invoice_number", headerName: "Számlasorszám", width: 110},
        {field: "DHL_delivery_number", headerName: "Oda fuvarlevélszám", width: 110},
        {field: "DHL_return_number", headerName: "Vissza fuvarlevélszám", width: 110},
        {field: "DHL_order", headerName: "Oda felvételazonosító", width: 110},
        {field: "DHL_order_back", headerName: "Vissza felvételazonosító", width: 110},
        {field: "delivery_courier_name", headerName: "Kiszállító futár", width: 140, valueGetter: p => {
                const conn = connections.obj[p.row.id]
                if (conn && conn?.delivery_courier) return couriers.find(c => c.id === conn.delivery_courier)?.name
                return ''
            }},
        {field: "return_courier_name", headerName: "Visszaszállító futár", width: 140, valueGetter: p => {
                const conn = connections.obj[p.row.id]
                if (conn && conn?.return_courier) return couriers.find(c => c.id === conn.return_courier)?.name
                return ''
            }},
        {field: "closed", headerName: "Lezárva", type: "boolean", width: 140},
        {field: "wrong", headerName: "Problémás", type: "boolean", width: 140},
        // {field: "price", headerName: "Ár", type: "number", width: 140},
        {field: "currency", headerName: "Pénznem", width: 110},
        {field: "days", headerName: "Napok száma", type: "number", width: 140},
        {field: "short_rent_HUF", headerName: "Rövid bérlés Forintban", type: "number", valueGetter: p => {
                const to = tourOffices.obj[p.row.tour_office]
                if (to) return to?.short_rent_HUF
                return ''
            }, width: 200},
        {field: "long_rent_HUF", headerName: "Hosszú bérlés Forintban", type: "number", valueGetter: p => {
                const to = tourOffices.obj[p.row.tour_office]
                if (to) return to?.long_rent_HUF
                return ''
            }, width: 200},
        {field: "short_rent_EUR", headerName: "Rövid bérlés Euróban", type: "number", valueGetter: p => {
                const to = tourOffices.obj[p.row.tour_office]
                if (to) return to?.short_rent_EUR
                return ''
            }, width: 200},
        {field: "long_rent_EUR", headerName: "Hosszú bérlés Euróban", type: "number", valueGetter: p => {
                const to = tourOffices.obj[p.row.tour_office]
                if (to) return to?.long_rent_EUR
                return ''
            }, width: 200},
    ]

    const onRowClick = (params) => {
        setCurrent(params['id'])
        toggleDetailOrderDialog(true)
    }

    const newDialogActions = <Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
        <Button size='large' variant='outlined' onClick={() => toggleOrderDialog(false)}>Mégsem</Button>
        <Button size='large' variant='outlined' color='success' onClick={() => {
            handleNewOrder()
            toggleOrderDialog(false)
        }}>Hozzáadás</Button>
    </Stack>

    const editDialogActions = <Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
        <Button size='large' variant='outlined' onClick={() => toggleEditOrderDialog(false)}>Mégsem</Button>
        <Button size='large' variant='outlined' color='success' onClick={() => {
            handleEditOrder()
            toggleEditOrderDialog(false)
        }}>Szerkesztés</Button>
    </Stack>

    const deleteDialogActions = <Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
        <Button size='large' variant='outlined' onClick={() => toggleDeleteOrderDialog(false)}>Mégsem</Button>
        <Button size='large' variant='contained' color='error' onClick={() => {
            toggleDeleteOrderDialog(false)
            deleteOrder()
        }}>törlés</Button>
    </Stack>

    return (
        <div>
            <PersistentDrawerLeft title="Megrendelések">

                <DataGridWrapper name='orders' onRowClick={onRowClick} rows={orders} columns={
                    columns.map(col => ({
                        ...col,
                        cellClassName: (params) => (`closed-${params.row['closed']} status-${params.row['order_status']}`)
                    }))}/>

                <Loading isLoading={loading}/>

                {permissions.includes('add_order') && <BasicSpeedDial onClick={() => (toggleOrderDialog(true))}/>}

                <DinoDialog
                    title="Új megrendelés"
                    open={isOrderDialogOpen}
                    handleClose={() => toggleOrderDialog(false)}
                    actions={newDialogActions}>
                    <OrderData reference={newOrderForm}/>
                </DinoDialog>

                <DinoDialog
                    title={getOrder(current)?.order_number + " szerkesztése"}
                    open={isEditOrderDialogOpen}
                    handleClose={() => toggleEditOrderDialog(false)}
                    actions={editDialogActions}>
                    <OrderData
                        reference={editOrderForm}
                        order={getOrder(current)}/>
                </DinoDialog>

                <DinoDialog
                    title="Szállítólevél szerkesztése"
                    open={isEditWaybillDialogOpen}
                    handleClose={() => (toggleEditWaybillDialog(false))}
                    actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                        <Button size='large' variant='outlined' onClick={() => toggleEditWaybillDialog(false)}>Mégsem</Button>
                        <Button size='large' variant='outlined' color='success' onClick={() => {
                            handleEditWaybill()
                            toggleEditWaybillDialog(false)
                        }}>Beállítás</Button>
                    </Stack>}>
                    <WaybillData reference={editWaybillForm} waybill={current}/>
                </DinoDialog>

                <DinoDialog
                    title="Matrica szerkesztése"
                    open={isEditStickerDialogOpen}
                    handleClose={() => (toggleEditStickerDialog(false))}
                    actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                        <Button size='large' variant='outlined' onClick={() => toggleEditStickerDialog(false)}>Mégsem</Button>
                        <Button size='large' variant='outlined' color='success' onClick={() => {
                            handleEditStickers()
                            toggleEditStickerDialog(false)
                        }}>Beállítás</Button>
                    </Stack>}>
                    <StickerData reference={editStickerForm} sticker={current}/>
                </DinoDialog>

                <DinoDialog
                    title="Futár beállítása"
                    open={isCourierOrderDialogOpen}
                    handleClose={() => (toggleCourierOrderDialog(false))}
                    actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                        <Button size='large' variant='outlined' onClick={() => toggleCourierOrderDialog(false)}>Mégsem</Button>
                        <Button size='large' variant='outlined' color='success' onClick={() => {
                            handleSetCourier()
                            toggleCourierOrderDialog(false)
                        }}>Beállítás</Button>
                    </Stack>}>
                    <ConnectionData reference={connectionForm} connection={connections.obj[current]}/>
                </DinoDialog>

                <TrackingDialog
                    model={getOrder(current)?.order_number}
                    open={isTrackingDialogOpen}
                    onClose={() => (toggleTrackingDialog(false))}
                    trackingInfo={tracking}/>

                <DinoDialog
                    handleClose={() => toggleDeleteOrderDialog(false)}
                    actions={deleteDialogActions}
                    open={isDeleteOrderDialogOpen}
                    title={`Biztosan törölni akarja: ${getOrder(current)?.order_number}?`}
                    subtitle='Minden kapcsolódó adat elvész.'/>

                <DinoDialog
                    handleClose={() => toggleDetailOrderDialog(false)}
                    open={isDetailOrderDialogOpen}
                    title={getOrder(current)?.order_number}
                    subtitle='Megrendelés'
                >
                    <OrderDetail orderId={current}/>
                </DinoDialog>

                <DinoDialog
                    handleClose={() => toggleValidateDialog(false)}
                    actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                        <Button size='large' variant='outlined'
                                onClick={() => toggleValidateDialog(false)}>Mégsem</Button>
                        <Button size='large' variant='outlined' color='error' onClick={() => {
                            toggleValidateDialog(false)
                            invalidate(current)
                        }}>érvénytelenítés</Button>
                    </Stack>}
                    open={isValidateDialogOpen}
                    title={`Biztosan érvényteleníti: ${getOrder(current)?.order_number}?`}
                    subtitle='Csak admin felhasználó tudja ezt visszavonni.'/>

                <DinoDialog
                    handleClose={() => toggleCloseOrderDialog(false)}
                    actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                        <Button size='large' variant='outlined'
                                onClick={() => toggleCloseOrderDialog(false)}>Mégsem</Button>
                        <Button size='large' variant='outlined' color='error' onClick={() => {
                            toggleCloseOrderDialog(false)
                            toggle(current, '')
                        }}>Lezárás</Button>
                    </Stack>}
                    open={isCloseOrderDialogOpen}
                    title={`Biztosan lezárja ezt: ${getOrder(current)?.order_number}?`}
                    subtitle='Indoklás után kinyitható újra.'/>

                <DinoDialog
                    title={`Biztosan kinyitja ezt: ${getOrder(current)?.order_number}?`}
                    open={isOpenOrderDialogOpen}
                    handleClose={() => toggleOpenOrderDialog(false)}
                    actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                        <Button size='large' variant='outlined' onClick={() => toggleCloseOrderDialog(false)}>Mégsem</Button>
                        <Button size='large' variant='outlined' color='error' onClick={() => {
                            toggleOpenOrderDialog(false)
                            let reason = getFormData(reasonForm.current)?.reason
                            if (reason !== undefined) toggle(current, reason)
                        }}>Kinyitás</Button>
                    </Stack>}>
                    <form ref={reasonForm} onSubmit={(event) => {
                        event.preventDefault()
                        toggleOpenOrderDialog(false)
                        let reason = getFormData(reasonForm.current).reason
                        toggle(current, reason)
                    }}>
                        <Stack spacing={2}>
                            <TextField required={true} name="reason" id="reason" label="Indoklás"/>
                        </Stack>
                    </form>
                </DinoDialog>

            </PersistentDrawerLeft>
        </div>
    );
};

export default Orders;
