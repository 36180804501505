import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import PropTypes from "prop-types";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

MyBarChart.propTypes = {
    receivers: PropTypes.object,
    name: PropTypes.string,
};

export default function MyBarChart({data, name}) {
    const labels = ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'];
    console.log(data)
    const formattedData = {
        labels,
        datasets: [
            {
                label: 'Lila',
                data: data['Lila'],
                backgroundColor: 'rgb(156,0,220)',
            },
            {
                label: 'Narancssárga',
                data: data['Narancssárga'],
                backgroundColor: 'rgb(255,80,4)',
            },
            {
                label: 'Oliva',
                data: data['Oliva'],
                backgroundColor: 'rgba(30,61,23,0.34)',
            },
            {
                label: 'Fehér',
                data: data['Fehér'],
                backgroundColor: 'rgb(255,255,255)',
            },
            {
                label: 'Piros',
                data: data['Piros'],
                backgroundColor: 'rgb(255,0,0)',
            },
            {
                label: 'Zöld',
                data: data['Zöld'],
                backgroundColor: 'rgb(0,255,0)',
            },
            {
                label: 'Szürke',
                data: data['Szürke'],
                backgroundColor: 'rgb(79,79,79)',
            },
            {
                label: 'Szürke E',
                data: data['Szürke E'],
                backgroundColor: 'rgb(194,191,191)',
            },
        ],
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: name,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };
    return <Bar options={options} data={formattedData} />;
}
