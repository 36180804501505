import {createContext} from "react";

const ConfigContext = createContext();

export default ConfigContext;

export const ConfigProvider = ({ children }) => {

    const contextData = {
        baseUrl: 'https://tgsadmin.tourguidesystem.hu/',
        /* baseUrl: 'https://tourguideback.trilogic.hu/', */
    }

    return <ConfigContext.Provider value={contextData}>{children}</ConfigContext.Provider>
}