import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';
import {Button, Chip, IconButton, Stack} from "@mui/material";
import _ from "lodash";
import GroupData from "./GroupData";
import DinoDialog from "../../components/Dialog";
import {getFormData} from "../../utils/FormUtils";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import ExpressionData from "./ExpressionData";


const QueryBuilder = forwardRef(({report, dataGetter, exclude = false}, ref) => {
    const [query, setQuery] = useState(exclude ? report?.exclude_json : report?.filter_json)
    const [groupEditOpen, setGroupEditOpen] = useState(false)
    const [newExpressionOpen, setNewExpressionOpen] = useState(false)
    const [editExpressionOpen, setEditExpressionOpen] = useState(false)
    const [currentExpression, setCurrentExpression] = useState({})
    const [currentPath, setCurrentPath] = useState({})
    const groupForm = useRef(null)
    const expressionForm = useRef(null)

    useImperativeHandle(ref, () => ({
        get() {
            return query
        }
    }));

    const removeNullUndefined = arr => arr.filter(v => v != null)

    const addGroup = (path, op) => {
        if (!path.length) {
            setQuery({[op]: []})
        } else {
            const currentValue = _.get(query, path)
            const newValue = {[op]: []}
            _.set(query, path, [...currentValue, newValue])
            setQuery({...query})
        }
    }

    const removeGroup = (path) => {
        const pathCopy = _.clone(path)
        pathCopy.pop()
        if (!pathCopy.length) {
            setQuery({})
        } else {
            // remove the part
            const deleted = _.omit(query, pathCopy.join('.'))
            // remove the undefined which was left after the removal
            pathCopy.pop()
            const currentValue = _.get(deleted, pathCopy)
            const cleaned = removeNullUndefined(currentValue)
            _.set(deleted, pathCopy, [...cleaned])
            // set the query
            setQuery(deleted)
        }
    }

    const addExpression = (path, expression) => {
        const currentValue = _.get(query, path)
        _.set(query, path, [...currentValue, expression])
        setQuery({...query})
    }

    const removeExpression = (path) => {
        const pathCopy = _.clone(path)
        const deleted = _.omit(query, pathCopy.join('.'))
        // remove the undefined which was left after the removal
        pathCopy.pop()
        const currentValue = _.get(deleted, pathCopy)
        const cleaned = removeNullUndefined(currentValue)
        _.set(deleted, pathCopy, [...cleaned])
        // set the query
        setQuery(deleted)
    }

    const editExpression = (path, editedExpression) => {
        const pathCopy = _.clone(path)
        pathCopy.pop()
        addExpression(pathCopy, editedExpression)
        removeExpression(path)
    }

    const createExpression = () => {
        const formData = getFormData(expressionForm.current)
        const key = (formData.prefix ? (formData.prefix + "__") : "") + formData.field + (formData.operator || "")
        return {[key]: formData?.value}
    }

    const displayExpression = (expression) => {
        const key = Object.keys(expression)[0]
        const val = Object.values(expression)[0]?.toString()
        const regex = /__(lte|gte|gt|lt|contains|endswith|isnull)?$/; // This regex matches the operator at the end
        const match = key.match(regex);
        if (match) {
            const extractedOperator = match[1] || '=';
            const parts = key.split(regex);
            const variableNames = parts[0]
            return <Stack direction='row' spacing={1}>
                <Chip label={variableNames}/>
                <Chip label={extractedOperator} variant='outlined'/>
                <Chip label={val}/>
            </Stack>
        } else {
            return <Stack direction='row' spacing={1}>
                <Chip label={key}/>
                <Chip label='=' variant='outlined'/>
                <Chip label={val}/>
            </Stack>
        }
    }

    const colorList = ['#812424', '#39721f', '#41487c', '#6b3779']

    const renderQuery = (query, path = []) => {
        const nestedQuery = query?.AND || query?.OR

        if (query === undefined) return

        if (!Object.keys(query).length) {
            // Kezdő állapot, {}
            return <div style={{padding: '10px', marginTop: '10px', border: '1px solid #616b75', borderRadius: '5px'}}>
                <Button onClick={() => {
                    setGroupEditOpen(true)
                    setCurrentPath(path)
                }} size='small' color='success' variant='contained'
                        startIcon={<AddCircleOutlineRoundedIcon/>}>Csoport</Button>
            </div>
        }

        if (nestedQuery) {
            const currentOperator = query?.AND ? 'AND' : 'OR'
            path = [...path, currentOperator]

            const filter = path.filter(i => typeof i === 'string');
            const color = colorList[filter.length % colorList.length]

            return <div style={{background: `${color}`, padding: '10px', marginTop: '10px', border: '1px solid #616b75', borderRadius: '5px'}}>
                <Stack direction='row' spacing={2}>
                    <p>{currentOperator}</p>
                    <Button onClick={() => {
                        setNewExpressionOpen(true)
                        setCurrentPath(path)
                    }} size='small' color='success' variant='contained'
                            startIcon={<AddCircleOutlineRoundedIcon/>}>Feltétel</Button>
                    <Button onClick={() => {
                        setGroupEditOpen(true)
                        setCurrentPath(path)
                    }} size='small' color='success' variant='contained'
                            startIcon={<AddCircleOutlineRoundedIcon/>}>Csoport</Button>
                    <Button onClick={() =>
                        removeGroup(path)
                    } size='small' variant='outlined' color='error'
                            startIcon={<DeleteOutlineRoundedIcon/>}>Csoport</Button>
                </Stack>
                {nestedQuery.map((c, i) => <div key={`i_${path}_${i}`}>
                    {renderQuery(c, [...path, i])}
                </div>)}
            </div>
        } else {
            return <Stack direction='row' spacing={2} marginTop={1}>
                {displayExpression(query)}
                <IconButton onClick={() => {
                    setCurrentExpression(query)
                    setCurrentPath(path)
                    setEditExpressionOpen(true)
                }} size='small' variant='outlined'><EditRoundedIcon/></IconButton>
                <IconButton onClick={() => removeExpression(path)} size='small' color='error' variant='outlined'><DeleteOutlineRoundedIcon/></IconButton>
            </Stack>
        }
    }

    return <>
        {renderQuery(query)}

        <DinoDialog
            title='Csoport hozzáadása'
            open={groupEditOpen}
            handleClose={() => setGroupEditOpen(false)}
            subtitle='Válasszon az ÉS és a VAGY közül'
            actions={<>
                <Button onClick={() => {
                    addGroup(currentPath, getFormData(groupForm.current).operator)
                    setGroupEditOpen(false)
                }} variant='contained' color='success'>Hozzáadás</Button>
                <Button onClick={() => setGroupEditOpen(false)} variant='outlined' color='error'>Mégsem</Button>
            </>}>
            <GroupData reference={groupForm}/>
        </DinoDialog>

        <DinoDialog
            title='Kifejezés hozzáadása'
            open={newExpressionOpen}
            handleClose={() => setNewExpressionOpen(false)}
            subtitle='Válasszon a mezők és az értékek közül'
            actions={<>
                <Button onClick={() => {
                    const expression = createExpression()
                    addExpression(currentPath, expression)
                    setNewExpressionOpen(false)
                }} variant='contained' color='success'>Hozzáadás</Button>
                <Button onClick={() => setNewExpressionOpen(false)} variant='outlined' color='error'>Mégsem</Button>
            </>}>
            <ExpressionData reference={expressionForm} report={report}/>
        </DinoDialog>

        <DinoDialog
            title='Kifejezés szerkesztése'
            open={editExpressionOpen}
            handleClose={() => setEditExpressionOpen(false)}
            subtitle='Válasszon a mezők és az értékek közül'
            actions={<>
                <Button onClick={() => {
                    const expression = createExpression()
                    editExpression(currentPath, expression)
                    setEditExpressionOpen(false)
                }} variant='contained' color='success'>Szerkesztés</Button>
                <Button onClick={() => setEditExpressionOpen(false)} variant='outlined' color='error'>Mégsem</Button>
            </>}>
            <ExpressionData reference={expressionForm} expression={currentExpression} report={report}/>
        </DinoDialog>
    </>
})

export default QueryBuilder;
