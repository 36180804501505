import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from "@mui/material/Box";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {Typography} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import IconButton from "@mui/material/IconButton";


export default function TrackingDialog(props) {

    const getIcon = (operation) => {
        const operations = {
            'ADDED': <AddIcon/>,
            'CHANGED': <EditIcon/>,
            'DELETED': <DeleteIcon/>,
        }
        return operations[operation]
    }

    const handleClose = () => {
        props.onClose()
    };

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
                maxWidth='lg' PaperProps={{style: {borderRadius: '16px', padding: 4}}}
                BackdropProps={{style: {backdropFilter: "blur(5px)"}}}
            >
                <IconButton onClick={handleClose} color="primary" aria-label="close" component="label"
                            style={{position: 'absolute', top: 10, right: 10}}>
                    <CloseOutlinedIcon/>
                </IconButton>
                <DialogTitle id="scroll-dialog-title">Nyomonkövetés: {props.model}</DialogTitle>
                <DialogContent>
                    <Box
                        component="div"
                        sx={{
                            '& .MuiTextField-root': {m: 1, width: '25ch'},
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        <Timeline position="alternate">
                            {props.trackingInfo.map(info =>
                                <TimelineItem key={info.id}>
                                    <TimelineOppositeContent
                                        sx={{m: 'auto 0'}}
                                        align="right"
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        {info.at}
                                    </TimelineOppositeContent>
                                    <TimelineSeparator>
                                        <TimelineConnector/>
                                        <TimelineDot>
                                            {getIcon(info.action.toUpperCase())}
                                        </TimelineDot>
                                        <TimelineConnector/>
                                    </TimelineSeparator>
                                    <TimelineContent sx={{py: '12px', px: 2}}>
                                        <Typography variant="h6" component="span">
                                            {info.action.toUpperCase()} by {info.user}
                                        </Typography>
                                        <br/>
                                        <Typography sx={{fontSize: 10}} variant="p">{info.message}</Typography>
                                    </TimelineContent>
                                </TimelineItem>)}
                        </Timeline>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button size='large' variant="outlined" color="success" onClick={handleClose}>ok</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
