import {InputLabel, MenuItem, Select} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import useCallDataApi from "../../hooks/data";


export default function ConnectionData({reference, connection}) {
    const [couriers, setCouriers] = useState([]);
    const [deliveryCourier, setDeliveryCourier] = useState(connection?.delivery_courier || null)
    const [returnCourier, setReturnCourier] = useState(connection?.return_courier || null)

    const {getData: getCouriers} = useCallDataApi("couriers")

    const getAllCouriers = () => {
        getCouriers().then(allCouriers => setCouriers(allCouriers.filter(c => c.valid)))
    }

    useEffect(() => {
        getAllCouriers()
    }, [])

    const handleDeliveryChange = (event) => {
        setDeliveryCourier(event.target.value)
    }

    const handleReturnChange = (event) => {
        setReturnCourier(event.target.value)
    }

    return <form ref={reference}>
        <Stack>
            <FormControl sx={{m: 1, minWidth: 120}}>
                <InputLabel id="courier-select">Kiszállító futár</InputLabel>
                <Select
                    labelId="courier-select"
                    label="Kiszállító utár"
                    id="delivery_courier"
                    name="nullable-delivery_courier"
                    value={deliveryCourier}
                    onChange={handleDeliveryChange}
                >
                    <MenuItem value={''}>none</MenuItem>
                    {couriers.map(courier => (
                        <MenuItem key={courier.id} value={courier.id}>{courier.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl sx={{m: 1, minWidth: 120}}>
                <InputLabel id="courier-select2">Visszaszállító futár</InputLabel>
                <Select
                    labelId="courier-select2"
                    label="Visszaszállító futár"
                    id="return_courier"
                    name="nullable-return_courier"
                    value={returnCourier}
                    onChange={handleReturnChange}
                >
                    <MenuItem value={''}>none</MenuItem>
                    {couriers.map(courier => (
                        <MenuItem key={courier.id} value={courier.id}>{courier.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    </form>;
}