import React, {useEffect, useState} from "react";
import { Grid } from "@mui/material";

import MyBarChart from "../components/charts/BarChart";
import MyPieChart from "../components/charts/PieChart";
import useCallDataApi from "../hooks/data";
import PersistentDrawerLeft from "../components/NewDrawer";
import Loading from "../components/Loading";


const HomePage = () => {
    const {getData: fetchOrders} = useCallDataApi("orders");
    const [receivers, setReceivers] = useState([])
    const [transmitters, setTransmitters] = useState([])
    const [months, setMonths] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const getData = async () => {
            const [r, t, m] = await Promise.all([
                fetchOrders('receivers'),
                fetchOrders('transmitters'),
                fetchOrders('by_month')
            ])
            if (r) setReceivers(r)
            if (t) setTransmitters(t)
            if (m) setMonths(m)
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
    }, [])

    return (
        <div>
            <PersistentDrawerLeft title="Kezelőpult">
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={8}>
                        <MyBarChart data={months} name="HAVI BONTÁS"/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MyPieChart name={"VEVŐK"} data={receivers}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <MyPieChart name={"ADÓK"} data={transmitters}/>
                    </Grid>
                </Grid>
            </PersistentDrawerLeft>
            <Loading isLoading={loading}/>
        </div>
    );
};

export default HomePage;
