import { useContext } from "react";
import AuthContext from "../context/AuthContext";

export function useAuthHeader() {
    const { authTokens } = useContext(AuthContext);
    return {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + String(authTokens?.access),
        },
    };
}
