import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import React from "react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const ReportPieChart = ({name, labels, datasets, backgroundColor}) => {
    const data = {
        labels: labels,
        datasets: [{
            name: `# of ${name}`,
            data: datasets,
            backgroundColor: backgroundColor
        }],

    };
    const options = {
        plugins: {
            title: {
                display: true,
                text: name,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };
    return <Doughnut options={options} data={data}/>

}

export default ReportPieChart