import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import React from "react";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const ReportLineChart = ({name, labels, datasets, backgroundColor}) => {
    const data = {
        labels: labels,
        datasets: datasets
    };

    const options = {
        plugins: {
            title: {
                display: true,
                text: name,
            },
        },
        responsive: true,
    };
    return <Line options={options} data={data}/>

}

export default ReportLineChart