import React, {useContext, useEffect, useRef, useState} from "react";
import BasicSpeedDial from "../../components/BasicSpeedDial";
import {GridActionsCellItem} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import useCallDataApi from "../../hooks/data";
import TourOfficeData from "./TourOfficeData";
import AuthContext from "../../context/AuthContext";
import {useSnackbar} from "notistack";
import TrackingDialog from "../../components/TrackingDialog";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CloseIcon from "@mui/icons-material/Close";
import DinoDataGrid from "../../components/DataGrid";
import {getFormData} from "../../utils/FormUtils";
import PersistentDrawerLeft from "../../components/NewDrawer";
import Loading from "../../components/Loading";
import TourOfficeDetail from "./TourOfficeDetail";
import DinoDialog from "../../components/Dialog";
import {Button, Stack} from "@mui/material";
import DataGridWrapper from "../../components/DataGridWrapper";


const TourOffices = () => {
    const [tourOffices, setTourOffices] = useState([]);
    const [isDeleteTourOfficeDialogOpen, toggleDeleteTourOfficeDialog] = useState(false)
    const [isEditTourOfficeDialogOpen, toggleEditTourOfficeDialog] = useState(false)
    const [isDetailDialogOpen, toggleDetailDialog] = useState(false)
    const [isTourOfficeDialogOpen, toggleTourOfficeDialog] = useState(false)
    const [isTrackingDialogOpen, toggleTrackingDialog] = useState(false)
    const [isValidateDialogOpen, toggleValidateDialog] = useState(false);
    const [current, setCurrent] = useState(1)
    const [tracking, setTracking] = useState([])
    const [loading, setLoading] = useState(true)
    const editTourOfficeForm = useRef(null);
    const newTourOfficeForm = useRef(null);
    const {getData: fetchTourOffices, createData, updateData, deleteData} = useCallDataApi("tour-offices");
    const {getData: getContactPersons} = useCallDataApi("contact-person");

    const {permissions} = useContext(AuthContext);
    const {enqueueSnackbar} = useSnackbar();

    const getTracking = (id) => {
        fetchTourOffices(`${id}/track`).then(t => setTracking(t))
    }

    const newTourOffice = (tourOfficeData) => {
        createData(tourOfficeData).then(newTourOffice => {
            setTourOffices(tourOffice => [...tourOffice, newTourOffice])
            enqueueSnackbar('Megrendelő mentve!', {variant: 'success'})
        })
    }

    const editTourOffice = (tourOfficeData) => {
        updateData(current, tourOfficeData).then(editedTourOffice => {
            const editedTourOffices = tourOffices.map(tourOffice => {
                if (current === tourOffice.id) return {...tourOffice, ...editedTourOffice}
                return tourOffice;
            });
            setTourOffices(editedTourOffices)
            enqueueSnackbar('Megrendelő szerkesztve!', {variant: 'success'})
        })
    }

    const deleteTourOffice = () => {
        deleteData(current).then(() => {
            setTourOffices(tourOffices.filter(tourOffice => (tourOffice.id !== current)))
            enqueueSnackbar('Megrendelő törölve!', {variant: 'success'})
        })
    }

    useEffect(() => {
        const getData = async () => {
            const [t] = await Promise.all([
                fetchTourOffices(),
                getContactPersons()
            ])
            if (t) setTourOffices(t)
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getTourOffice = (id) => tourOffices.find(tourOffice => (tourOffice.id === id))

    const handleEditTourOffice = () => {
        const data = getFormData(editTourOfficeForm.current)
        editTourOffice({is_company: false, ...data})
    }

    const handleNewTourOffice = () => {
        const data = getFormData(newTourOfficeForm.current)
        newTourOffice(data)
    }

    const invalidate = (id) => {
        fetchTourOffices(`${id}/invalidate`).then(() => {
            const editedTourOfficeList = tourOffices.filter(t => (current !== t.id))
            setTourOffices(editedTourOfficeList)
            enqueueSnackbar("Érvénytelenítve!", {variant: 'success'})
        })
    }

    const columns = [
        {
            field: "manage",
            headerName: "Kezelés",
            sortable: false,
            type: "actions",
            getActions: (params) => {
                let menu = []
                if (permissions.includes('delete_touroffice')) menu.push(<GridActionsCellItem
                    icon={<DeleteIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleDeleteTourOfficeDialog(true)
                    }}
                    label="Törlés"
                    showInMenu={true}
                />)
                if (permissions.includes('change_touroffice')) menu.push(<GridActionsCellItem
                    icon={<EditIcon/>}
                    label="Szerkesztés"
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleEditTourOfficeDialog(true)
                    }}
                    showInMenu={true}/>)

                if (permissions.includes('can_track_touroffice')) menu.push(<GridActionsCellItem
                    icon={<AccountTreeIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        getTracking(params.row["id"])
                        toggleTrackingDialog(true)
                    }}
                    label="Nyomonkövetés"
                    showInMenu={true}
                />)
                if (permissions.includes('can_validate_touroffice')) menu.push(<GridActionsCellItem
                    icon={<CloseIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleValidateDialog(true)
                    }}
                    label="Érvénytelenítés"
                    showInMenu={true}
                />)
                return menu
            },
        },
        {field: "id", headerName: "ID", width: 70},
        {field: "name", headerName: "Név", width: 130},
        {field: "short_name", headerName: "Rövid név", width: 100},
        {field: "address", headerName: "Cím", width: 150},
        {field: "vat_number", headerName: "Adószám", width: 110},
        {field: "is_company", type: "boolean", headerName: "Egyéb cég?", width: 100},
        {field: "short_rent_HUF", headerName: "Rövid bérleti díj HUF", width: 110},
        {field: "long_rent_HUF", headerName: "Hosszú bérleti díj HUF", width: 110},
        {field: "short_rent_EUR", headerName: "Rövid bérleti díj EUR", width: 110},
        {field: "long_rent_EUR", headerName: "Hosszú bérleti díj EUR", width: 110},
    ];

    // const speedDialActions = [];
    //
    // if (permissions.includes('add_touroffice')) {
    //     speedDialActions.push({
    //         icon: (
    //             <AddIcon
    //                 onClick={() => (
    //                     toggleTourOfficeDialog(true)
    //                 )}
    //             />
    //         ),
    //         name: "New Tour Office",
    //     })
    // }

    const onRowClick = (params) => {
        setCurrent(params['id'])
        toggleDetailDialog(true)
    }

    const newDialogActions = <Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
        <Button size='large' variant='outlined' onClick={() => toggleTourOfficeDialog(false)}>Mégsem</Button>
        <Button size='large' variant='outlined' color='success' onClick={() => {
            handleNewTourOffice()
            toggleTourOfficeDialog(false)
        }}>Hozzáadás</Button>
    </Stack>

    const editDialogActions = <Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
        <Button size='large' variant='outlined' onClick={() => toggleEditTourOfficeDialog(false)}>Mégsem</Button>
        <Button size='large' variant='outlined' color='success' onClick={() => {
            handleEditTourOffice()
            toggleEditTourOfficeDialog(false)
        }}>Szerkesztés</Button>
    </Stack>

    const invalidateDialogActions = <Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
        <Button size='large' variant='outlined' onClick={() => toggleValidateDialog(false)}>Mégsem</Button>
        <Button size='large' variant='outlined' color='error' onClick={() => {
            toggleValidateDialog(false)
            invalidate(current)
        }}>érvénytelenítés</Button>
    </Stack>

    const deleteDialogActions = <Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
        <Button size='large' variant='outlined' onClick={() => toggleDeleteTourOfficeDialog(false)}>Mégsem</Button>
        <Button size='large' variant='contained' color='error' onClick={() => {
            toggleDeleteTourOfficeDialog(false)
            deleteTourOffice(current)
        }}>törlés</Button>
    </Stack>

    return (
        <div style={{height: "100vh"}}>
            <PersistentDrawerLeft title="Megrendelők">
                <DataGridWrapper name='tour-offices' onRowClick={onRowClick} rows={tourOffices} columns={columns}/>
                <Loading isLoading={loading}/>
            </PersistentDrawerLeft>

            {permissions.includes('add_touroffice') && <BasicSpeedDial onClick={() => toggleTourOfficeDialog(true)}/>}

            <DinoDialog
                handleClose={() => toggleDeleteTourOfficeDialog(false)}
                actions={deleteDialogActions}
                open={isDeleteTourOfficeDialogOpen}
                title={`Biztosan ki akarja törölni: ${getTourOffice(current)?.name}?`}
                subtitle='Minden kapcsolódó adat elvész.'/>

            <DinoDialog
                title="Megrendelő szerekesztése"
                open={isEditTourOfficeDialogOpen}
                handleClose={() => toggleEditTourOfficeDialog(false)}
                actions={editDialogActions}>
                <TourOfficeData reference={editTourOfficeForm} tourOffice={getTourOffice(current)}/>
            </DinoDialog>

            <TrackingDialog
                model={getTourOffice(current)?.name}
                open={isTrackingDialogOpen}
                onClose={() => (toggleTrackingDialog(false))}
                trackingInfo={tracking}/>

            <DinoDialog
                title="Új megrendelő"
                open={isTourOfficeDialogOpen}
                handleClose={() => toggleTourOfficeDialog(false)}
                actions={newDialogActions}>
                <TourOfficeData reference={newTourOfficeForm}/>
            </DinoDialog>

            <DinoDialog
                handleClose={() => toggleValidateDialog(false)}
                actions={invalidateDialogActions}
                open={isValidateDialogOpen}
                title={`Biztosan érvényteleníti: ${getTourOffice(current)?.name}?`}
                subtitle='Csak admin felhasználó tudja ezt visszavonni.'/>

            <DinoDialog
                handleClose={() => toggleDetailDialog(false)}
                open={isDetailDialogOpen}
                title={getTourOffice(current)?.name}
                subtitle={getTourOffice(current)?.short_name}
            >
                <TourOfficeDetail tourOfficeId={current}/>
            </DinoDialog>
        </div>
    );
};

export default TourOffices;
