const getFormData = (form) => {
    console.log(`getFormData started with: ${form}`)
    if (!form.checkValidity()) {
        console.log(`Form was not valid! :(`)
        form.reportValidity()
        // return
    }
    const formData = new FormData(form)
    console.log(`getFormData -> formData: ${formData}`)
    let object = {};
    formData.forEach((value, key) => {

        console.log(`getFormData -> forEach -> key: ${key} & value: ${value}`)

        if (key.startsWith('logicalfield-')) {
            const newKey = key.replace('logicalfield-', '')

            console.log(`getFormData -> forEach logicalfield  ->newKey: ${newKey} & value: ${value}`)

            object[newKey] = value === 'true'
        } else if (key.startsWith('nullable-')) {
            const newKey = key.replace('nullable-', '')

            console.log(`getFormData -> forEach nullable  ->newKey: ${newKey} & value: ${value}`)

            object[newKey] = value === '' ? null : value
        } else {
            if (value !== "") {

                console.log(`getFormData -> forEach else not empty value -> key: ${key} & value: ${value}`)

                object[key] = value
            } else {
                console.log('EMPTY!!!!')
            }
        }
        console.log('------------------------------------------------------')
    });
    return object
}

const getFormDataNative = (form, extra=[]) => {
    if (!form.checkValidity()) {
        form.reportValidity()
        return
    }
    let formData = new FormData(form);
    // remove empty image for update
    for (let item of formData.entries()) {
        if (typeof item[1] === 'object' && item[1].size === 0) formData.delete(item[0])
    }
    extra.forEach(e => formData.append(e.key, e.value));
    return formData;
}

const getFormDataWithImage = (form, img, extra) => {
    if (!form.checkValidity()) {
        form.reportValidity()
        return
    }
    let formData = new FormData(form)
    if (img) formData.append("image", img, img.name);
    extra.forEach(e => formData.append(e.key, e.value));
    return formData;
}

export {getFormData, getFormDataNative, getFormDataWithImage}