import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import {Typography} from "@mui/material";
import PropTypes from "prop-types";

ChartJS.register(ArcElement, Tooltip, Legend);

MyPieChart.propTypes = {
    data: PropTypes.object,
    name: PropTypes.string,
};

export default function MyPieChart({data, name}) {
    const labels = ['Lila', 'Narancssárga', 'Oliva', 'Fehér', 'Piros', 'Zöld', 'Szürke', 'Szürke E'];
    let ordered = []
    for (let label of labels) {
        ordered.push(data[label])
    }
    console.log(ordered)

    const options = {
        plugins: {
            title: {
                display: true,
                text: name,
            },
        },
        responsive: true,
    };

    let dummy = {
        labels: labels,
        datasets: [
            {
                label: '# of Votes',
                data: ordered,
                backgroundColor: [
                    'rgb(156,0,220)',
                    'rgb(255,80,4)',
                    'rgba(30,61,23,0.34)',
                    'rgb(255,255,255)',
                    'rgb(255,0,0)',
                    'rgb(0,255,0)',
                    'rgb(79,79,79)',
                    'rgb(194,191,191)',
                ],
                borderColor: [
                    'rgb(156,0,220)',
                    'rgb(255,80,4)',
                    'rgba(30,61,23,0.34)',
                    'rgb(255,255,255)',
                    'rgb(255,0,0)',
                    'rgb(0,255,0)',
                    'rgb(79,79,79)',
                    'rgb(194,191,191)',
                ],
                borderWidth: 1,
            },
        ],
    };

    return <Doughnut options={options} data={dummy} />
}
