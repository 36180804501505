import {Box, Chip, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Typography} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import useCallDataApi from "../../hooks/data";
import {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";

const ReportPartData = ({reference, reportPart, fields}) => {
    const {getData} = useCallDataApi('result-type')
    const [resultTypes, setResultTypes] = useState([]);
    const [selectedResultType, setSelectedResultType] = useState(reportPart?.result_type?.id || ``)
    const [selectedFields, setSelectedFields] = useState(reportPart?.fields?.fields || [])
    const theme = useTheme();

    const handleChange = (event) => {
        setSelectedResultType(event.target.value);
        setSelectedFields([])
    };

    const handleFieldChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedFields(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    useEffect(() => {
        getData().then(d => setResultTypes(d))
    }, [])

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    function getStyles(field, theme) {
        return {
            fontWeight:
                fields.indexOf(field) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const getResultType = (id) => resultTypes.find(r => r.id === id)

    const getField = (field) => fields.find(f => f?.name === field)

    const canSelectMenuItem = (item) => {
        const resType = getResultType(selectedResultType);
        const notSelected = !selectedFields.includes(item);
        const maxFieldNumber = selectedFields.length >= resType?.field_number;
        const wrongType = !resType?.accepted_fields[selectedFields.length]?.includes(getField(item)?.type);
        if (maxFieldNumber) return !(selectedFields[selectedFields.length - 1] === item)
        return (maxFieldNumber && notSelected) || wrongType
    }

    return <form ref={reference}>
        <Stack spacing={2} marginTop={2}>
            <TextField required id="name" name="name" label="Mi legyen a neve?" fullWidth defaultValue={reportPart?.name || ''}/>
            {/*<TextField required id="fields" name="fields" label="fields" fullWidth defaultValue={reportPart?.fields || ''}/>*/}
            <FormControl fullWidth>
                <InputLabel id="content-type-select-label">Művelet</InputLabel>
                <Select
                    labelId="content-type-select-label"
                    id="content-type-select"
                    name='result_type'
                    value={selectedResultType}
                    label="Művelet"
                    required
                    onChange={handleChange}
                >
                    {
                        Array.isArray(resultTypes) && resultTypes.length > 0 ?
                            resultTypes.map((c) => (
                                <MenuItem key={c?.id} value={c?.id}>{c?.name}</MenuItem>
                            )) :
                            // DEFAULT
                            <MenuItem disabled={true} value=''>No valid options...</MenuItem>
                    }
                </Select>
            </FormControl>
            <Typography variant='p'>{getResultType(selectedResultType)?.description}</Typography>
            <Typography variant='p'>{selectedResultType && 'Mezők száma: ' + getResultType(selectedResultType)?.field_number}</Typography>

            <FormControl>
                <InputLabel id="demo-multiple-chip-label">Mező(k)</InputLabel>
                <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    name='fields'
                    fullWidth
                    value={selectedFields}
                    onChange={handleFieldChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Mező(k)" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected?.map((value) => <Chip key={value} label={value} />
                            )}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                >
                    {fields.map((field) => (
                        <MenuItem
                            key={field.name}
                            value={field.name}
                            disabled={canSelectMenuItem(field.name)}
                            style={getStyles(field.name, theme)}
                        >
                            {field.verbose_name} - {field.type}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>



        </Stack>
    </form>
}

export default ReportPartData