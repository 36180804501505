import {useHistory, useParams} from "react-router-dom";
import React, {useContext, useEffect, useRef, useState} from "react";
import useCallDataApi from "../../hooks/data";
import {Card, CardActions, CardContent, Chip, Grid, IconButton, Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import BasicSpeedDial from "../../components/BasicSpeedDial";
import AuthContext from "../../context/AuthContext";
import DinoDialog from "../../components/Dialog";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ReportPartData from "./ReportPartData";
import {getFormData} from "../../utils/FormUtils";
import {useSnackbar} from "notistack";
import ReportBarChart from "./charts/ReportBarChart";
import ReportPieChart from "./charts/ReportPieChart";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import PictureAsPdfRoundedIcon from '@mui/icons-material/PictureAsPdfRounded';
import ReportLineChart from "./charts/ReportLineChart";
import PersistentDrawerLeft from "../../components/NewDrawer";
import Loading from "../../components/Loading";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import QueryDisplay from "./QueryDisplay";
import {thousandSeparator} from "../../utils/NumberUtils";


const Report = () => {
    const params = useParams()
    const {getData: fetchReport} = useCallDataApi('report')
    const {postData: newReportPart, updateData: updateReportPart, deleteData: deleteReportPart} = useCallDataApi('report-part')
    const {getFile: getReportCsv} = useCallDataApi('report')
    const [report, setReport] = useState({results: {}})
    const [results, setResults] = useState([])
    const [query, setQuery] = useState({})
    // const [filters, setFilters] = useState({})
    const [fields, setFields] = useState([])
    const [current, setCurrent] = useState(1)
    const [isNewReportPartDialogOpen, setNewReportPartDialogOpen] = useState(false)
    const [isEditReportPartDialogOpen, setEditReportPartDialogOpen] = useState(false)
    const [isDeleteReportPartDialogOpen, setDeleteReportPartDialogOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const {permissions} = useContext(AuthContext)
    const reportPartForm = useRef()
    const {enqueueSnackbar} = useSnackbar()
    const history = useHistory()

    useEffect(() => {
        const getData = async () => {
            const [r, f, p] = await Promise.all([
                fetchReport(`${params?.id}/report_result`),
                fetchReport(`${params?.id}/get_fields`),
                fetchReport(params?.id)
            ])
            if (r) {
                setResults(r?.results)
                setQuery(r?.filter)
                console.log('hi')
                console.log(r)
                console.log('hi')
                // setFilters(r?.filter)
            }
            if (f) setFields(f)
            if (p) setReport(p)
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const findReportPart = (id) => results.find(r => r?.id === id)

    const createReportPart = () => {
        const formData = getFormData(reportPartForm.current)
        newReportPart('', {...formData, report: params?.id}).then(n => {
            setResults([...results, n])
            enqueueSnackbar('Kimutatás hozzáadva!', {variant: 'success'})
        })
    }

    const destroyReportPart = () => {
        deleteReportPart(current).then(() => {
            setResults(results.filter(r => r.id !== current))
            enqueueSnackbar('Kimutatás törölve!', {variant: 'success'})
        })
    }

    const editReportPart = () => {
        const formData = getFormData(reportPartForm.current)
        const fields = {fields: [formData.fields]}
        updateReportPart(current, {...formData, fields: fields, report: params?.id}).then(editedReportPart => {
            const editedReportPartList = results.map(reportPart => {
                if (current === reportPart.id) return {...reportPart, ...editedReportPart}
                return reportPart;
            });
            setResults(editedReportPartList)
            enqueueSnackbar('Kimutatás szerkesztve!', {variant: 'success'})
        })
    }

    const resultFormatMap = (resultFormat, result) => {
        if (!result) return 'Hibás kimutatás!!!'
        switch (resultFormat) {
            case 'barchart':
                return <ReportBarChart name={result?.name} datasets={result?.result?.datasets} labels={result?.result?.labels}/>
            case 'linechart':
                return <ReportLineChart name={result?.name} datasets={result?.result?.datasets} labels={result?.result?.labels}/>
            case 'piechart':
                return <ReportPieChart name={result?.name} datasets={result?.result?.datasets} labels={result?.result?.labels} backgroundColor={result?.result?.backgroundColor}/>
            default:
                return 'Result: ' + thousandSeparator(result?.result)
        }
    }

    const downloadCsv = (id) => {
        getReportCsv(`${id}/csv_view`, 'csv').then(() => enqueueSnackbar('CSV downloaded!', {variant: 'success'}))
    }

    const speedDialActions = [{
        icon: (
            <PictureAsPdfRoundedIcon
                onClick={() => (
                    window.print()
                )}
            />
        ),
        name: "PDF mentése",
    }, {
        icon: (
            <DownloadRoundedIcon
                onClick={() => (
                    downloadCsv(params?.id)
                )}
            />
        ),
        name: "CSV letöltése",
    }];

    if (permissions.includes('add_reportpart')) speedDialActions.push({
        icon: (
            <AddCircleRoundedIcon
                onClick={() => (
                    setNewReportPartDialogOpen(true)
                )}
            />
        ),
        name: "Új kimutatás",
    })




    return <div style={{height: "100vh"}}>
        <PersistentDrawerLeft title={report?.name}>
            <Stack marginBottom={2} direction='row'>
                <Button variant='outlined' startIcon={<ArrowBackIosNewRoundedIcon/>} onClick={() => history.push('/reports')}>Vissza</Button>
            </Stack>
            <QueryDisplay query={query}/>

            <Grid container spacing={2}>
                {results?.map((r, i) => <Grid key={`report_${i}`} item xs={12} lg={6}>
                    <Card sx={{maxWidth: '100%'}}>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Name: {r?.name}
                            </Typography>
                            <Typography gutterBottom variant="p" component="div">
                                {resultFormatMap(r?.result_type?.result_format, r)}
                            </Typography>
                            <Typography gutterBottom variant="p" component="div">
                                Result type: {r?.result_type?.name}
                            </Typography>
                            <Typography gutterBottom variant="p" component="div">
                                Field(s): {r?.fields?.fields?.map(f => <Chip key={f} size='small' label={f}/>)}
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="medium" variant='outlined' color='success' onClick={() => {
                                setCurrent(r?.id)
                                setEditReportPartDialogOpen(true)
                            }}>Szerkesztés</Button>
                            <Button size="medium" variant='outlined' color='error' onClick={() => {
                                setCurrent(r?.id)
                                setDeleteReportPartDialogOpen(true)
                            }}>Törlés</Button>
                        </CardActions>
                    </Card>
                </Grid>)}
            </Grid>
            <Loading isLoading={loading}/>
            <BasicSpeedDial actions={speedDialActions}/>

            <DinoDialog title='Új kimutatás'
                        subtitle='Új kimutatás'
                        open={isNewReportPartDialogOpen}
                        handleClose={() => setNewReportPartDialogOpen(false)}
                        actions={<>
                            <Button variant='outlined' style={{padding: 15}} onClick={() => setNewReportPartDialogOpen(false)}>mégsem</Button>
                            <Button variant='contained' style={{padding: 15}} onClick={() => {
                                setNewReportPartDialogOpen(false)
                                createReportPart()
                            }} endIcon={<AddIcon/>}>Hozzáadás</Button>
                        </>}>
                <ReportPartData reference={reportPartForm} fields={fields}/>
            </DinoDialog>

            <DinoDialog title='Kimutatás szerkesztése'
                        subtitle='Kimutatás szerkesztése'
                        open={isEditReportPartDialogOpen}
                        handleClose={() => setEditReportPartDialogOpen(false)}
                        actions={<>
                            <Button variant='outlined' style={{padding: 15}} onClick={() => setEditReportPartDialogOpen(false)}>mégsem</Button>
                            <Button variant='contained' style={{padding: 15}} onClick={() => {
                                setEditReportPartDialogOpen(false)
                                editReportPart()
                            }} endIcon={<EditIcon/>}>Szerkesztés</Button>
                        </>}>
                <ReportPartData reference={reportPartForm} reportPart={findReportPart(current)} fields={fields}/>
            </DinoDialog>

            <DinoDialog title='Kimutatás törlése'
                        subtitle='Kimutatás törlése'
                        open={isDeleteReportPartDialogOpen}
                        handleClose={() => setDeleteReportPartDialogOpen(false)}
                        actions={<>
                            <Button variant='outlined' style={{padding: 15}} onClick={() => setDeleteReportPartDialogOpen(false)}>mégsem</Button>
                            <Button variant='contained' color='error' style={{padding: 15}} onClick={() => {
                                setDeleteReportPartDialogOpen(false)
                                destroyReportPart()
                            }} endIcon={<DeleteIcon/>}>Törlés</Button>
                        </>}/>
        </PersistentDrawerLeft>
    </div>
}

export default Report