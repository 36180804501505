import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import AccountCircle from "@mui/icons-material/AccountCircle";
import packageInfo from "../../package.json";
import MoreIcon from "@mui/icons-material/MoreVert";
import {useContext} from "react";
import AuthContext from "../context/AuthContext";
import ModeContext from "../context/ModeContext";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {Link} from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import EmojiTransportationIcon from "@mui/icons-material/EmojiTransportation";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import MopedRoundedIcon from "@mui/icons-material/MopedRounded";
import AssessmentIcon from "@mui/icons-material/Assessment";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function PersistentDrawerLeft({title, children}) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    let {permissions, username, logoutUser} = useContext(AuthContext)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const { theme: mode, toggle } = useContext(ModeContext);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = () => (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={menuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {/*<MenuItem onClick={handleMenuClose}>Profile</MenuItem>*/}
            {/*<MenuItem onClick={handleMenuClose}>My account</MenuItem>*/}
            <MenuItem onClick={logoutUser}>Kijelentkezés</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = () => (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem>
                <IconButton size="large" onClick={toggle} color="inherit">
                    {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                </IconButton>
                <p>Mode</p>
            </MenuItem>
            {/*<MenuItem>*/}
            {/*    <IconButton*/}
            {/*        size="large"*/}
            {/*        aria-label="show 17 new notifications"*/}
            {/*        color="inherit"*/}
            {/*    >*/}
            {/*        <Badge badgeContent={17} color="error">*/}
            {/*            <NotificationsIcon />*/}
            {/*        </Badge>*/}
            {/*    </IconButton>*/}
            {/*    <p>Notifications</p>*/}
            {/*</MenuItem>*/}
            <MenuItem onClick={logoutUser}>

                <IconButton
                    // size="large"
                    // aria-label="account of current user"
                    // aria-controls="primary-search-account-menu"
                    // aria-haspopup="true"
                    // color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Log out</p>
            </MenuItem>
        </Menu>
    );

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ display: 'flex' }}>
            {renderMenu()}
            {renderMobileMenu()}
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        sx={{
                            marginRight: '36px',
                            ...(open && {display: 'none'}),
                        }}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{flexGrow: 1, display: { xs: 'none', sm: 'block' }}}>
                        {title}
                    </Typography>
                    {/*    <Search>*/}
                    {/*    <SearchIconWrapper>*/}
                    {/*        <SearchIcon />*/}
                    {/*    </SearchIconWrapper>*/}
                    {/*    <StyledInputBase*/}
                    {/*        placeholder="Search…"*/}
                    {/*        inputProps={{ 'aria-label': 'search' }}*/}
                    {/*    />*/}
                    {/*</Search>*/}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <IconButton size="large" onClick={toggle} color="inherit">
                            {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton>
                        {/*<IconButton size="large" aria-label="show 4 new mails" color="inherit">*/}
                        {/*    <Badge badgeContent={4} color="error">*/}
                        {/*        <MailIcon />*/}
                        {/*    </Badge>*/}
                        {/*</IconButton>*/}
                        {/*<IconButton*/}
                        {/*    size="large"*/}
                        {/*    aria-label="show 17 new notifications"*/}
                        {/*    color="inherit"*/}
                        {/*>*/}
                        {/*    <Badge badgeContent={17} color="error">*/}
                        {/*        <NotificationsIcon />*/}
                        {/*    </Badge>*/}
                        {/*</IconButton>*/}
                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />

                        </IconButton>
                        <Typography variant="p" sx={{alignSelf: 'center', marginLeft: '15px'}}>{username} | v: {packageInfo?.version}</Typography>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <Link className="menu-item" to="/">
                        <ListItem>
                            <ListItemIcon>
                                <HomeIcon/>
                            </ListItemIcon>
                            <ListItemText>Kezelőpult</ListItemText>
                        </ListItem>
                    </Link>
                    {permissions.includes("view_order")?
                        <Link className="menu-item" to="/orders">
                            <ListItem>
                                <ListItemIcon>
                                    <HeadphonesIcon/>
                                </ListItemIcon>
                                <ListItemText>Megrendelések</ListItemText>
                            </ListItem>
                        </Link>:<span/>}
                    {permissions.includes("view_touroffice")?
                        <Link className="menu-item" to="/tour-offices">
                            <ListItem>
                                <ListItemIcon>
                                    <EmojiTransportationIcon/>
                                </ListItemIcon>
                                <ListItemText>Megrendelők</ListItemText>
                            </ListItem>
                        </Link>:<span/>}
                    {permissions.includes("view_tourguide")?
                        <Link className="menu-item" to="/tour-guides">
                            <ListItem>
                                <ListItemIcon>
                                    <PeopleAltIcon/>
                                </ListItemIcon>
                                <ListItemText>Csoportkísérők</ListItemText>
                            </ListItem>
                        </Link>:<span/>}
                    {permissions.includes("view_daily")?
                        <Link className="menu-item" to="/daily">
                            <ListItem>
                                <ListItemIcon>
                                    <ForwardToInboxIcon/>
                                </ListItemIcon>
                                <ListItemText>Kiszállítások</ListItemText>
                            </ListItem>
                        </Link>:<span/>}
                    {permissions.includes("view_daily")?
                        <Link className="menu-item" to="/courier">
                            <ListItem>
                                <ListItemIcon>
                                    <MopedRoundedIcon/>
                                </ListItemIcon>
                                <ListItemText>Futárok</ListItemText>
                            </ListItem>
                        </Link>:<span/>}
                    {permissions.includes("view_report")?
                        <Link className="menu-item" to="/reports">
                            <ListItem>
                                <ListItemIcon>
                                    <AssessmentIcon/>
                                </ListItemIcon>
                                <ListItemText>Riportok</ListItemText>
                            </ListItem>
                        </Link>:<span/>}
                    <Divider/>
                </List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                {children}
            </Main>
        </Box>
    );
}