import {
    Card,
    CardActions,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid, IconButton,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import useCallDataApi from "../../hooks/data";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import ContactPersonData from "./ContactPersonData";
import {useSnackbar} from "notistack";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import {random} from "@mui/x-data-grid-generator";
import FormControl from "@mui/material/FormControl";
import {getFormData} from "../../utils/FormUtils";
import DinoDialog from "../../components/Dialog";


export default function TourOfficeData({reference, tourOffice}) {
    const [isCompany, setIsCompany] = useState(tourOffice?.is_company || false)
    const [contactPersons, setContactPersons] = useState([]);
    const [current, setCurrent] = useState(1);
    const [isDeleteContactPersonDialogOpen, toggleDeleteContactPersonDialog] = useState(false)
    const [isEditContactPersonDialogOpen, toggleEditContactPersonDialog] = useState(false)
    const [isContactPersonDialogOpen, toggleContactPersonDialog] = useState(false)

    const {postData: postContactPerson, createData, updateData, deleteData} = useCallDataApi("contact-person");

    useEffect(() => {
        if (tourOffice) postContactPerson('get_for_tour_office', {tour_office_id: tourOffice?.id}).then(c => setContactPersons(c))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const newContactPersonForm = useRef(null);
    const {enqueueSnackbar} = useSnackbar()

    const handleChange = () => {
        setIsCompany(!isCompany)
    }

    const getContactPerson = (id) => {
        return contactPersons.filter(p => p.id === id)[0]
    }

    const getContactPersonWithoutId = () => {
        return contactPersons.filter(p => p?.id === undefined)
    }

    const newContactPerson = (ContactPersonData) => {
        if (tourOffice !== undefined) {
            createData(ContactPersonData).then(newContactPerson => {
                setContactPersons(contactPerson => [...contactPerson, newContactPerson])
                enqueueSnackbar('Kapcsolattartó mentve!', {variant: 'success'})
            })
        } else {
            setContactPersons([...contactPersons, ContactPersonData])
        }

    }

    const setEditedToState = (editedContact) => {
        const editedContactList = contactPersons.map(c => {
            if (current === c.id) return {...c, ...editedContact}
            return c;
        });
        setContactPersons(editedContactList)
    }

    const editContactPerson = (contactData) => {
        updateData(current, contactData).then(editedContact => {
            setEditedToState(editedContact)
            enqueueSnackbar('Kapcsolattartó szerkesztve!', {variant: 'success'})
        })
    }

    const deleteContactPerson = () => {
        deleteData(current).then(() => {
            setContactPersons(contactPersons.filter(contact => (contact.id !== current)))
            enqueueSnackbar('Törölve!', {variant: 'success'})
        })
    }

    const handleEditContactPerson = () => {
        let formData = getFormData(newContactPersonForm.current)
        let updated = {...getContactPerson(current), ...formData}
        delete updated.id
        editContactPerson(updated)
    }

    const handleNewContactPerson = () => {
        const ContactPersonData = getFormData(newContactPersonForm.current)
        newContactPerson({...ContactPersonData, tour_office: tourOffice?.id})
    }

    const sendMail = (contact) => {
        if (contact?.email) window.open(`mailto:${contact?.email}`)
    }

    const callNumber = (contact) => {
        if (contact?.phone) window.open(`tel:${contact?.phone}`)
    }

    return <>
        <form ref={reference}>
            <Stack spacing={2} marginTop={2}>
                <TextField id="name" name="name" label="Név" defaultValue={tourOffice?.name || ''}/>
                <TextField id="short_name" name="short_name" label="Rövid név"
                           defaultValue={tourOffice?.short_name || ''}/>
                <TextField id="address" name="address" label="Cím" defaultValue={tourOffice?.address || ''}/>
                <TextField id="vat_number" name="vat_number" label="Adószám"
                           defaultValue={tourOffice?.vat_number || ''}/>
                <TextField id="short_rent_HUF" name="short_rent_HUF" label="Rövid bérleti díj HUF"
                           defaultValue={tourOffice?.short_rent_HUF || ''}/>
                <TextField id="long_rent_HUF" name="long_rent_HUF" label="Hosszú bérleti díj HUF"
                           defaultValue={tourOffice?.long_rent_HUF || ''}/>
                <TextField id="short_rent_EUR" name="short_rent_EUR" label="Rövid bérleti díj EUR"
                           defaultValue={tourOffice?.short_rent_EUR || ''}/>
                <TextField id="long_rent_EUR" name="long_rent_EUR" label="Hosszú bérleti díj EUR"
                           defaultValue={tourOffice?.long_rent_EUR || ''}/>
                {tourOffice === undefined ?
                    <input hidden readOnly id="contacts" name="contacts"
                           value={JSON.stringify(getContactPersonWithoutId())}/> : <></>}
                <input type="hidden" name="logicalfield-is_company" value={isCompany}/>
                <FormControl>
                    <FormControlLabel
                        label="Egyéb cég?"
                        control={
                            <Checkbox
                                id="is_company"
                                checked={isCompany}
                                onClick={handleChange}
                            />
                        }
                    />
                </FormControl>
            </Stack>
        </form>
        <Button startIcon={<AddIcon/>}
                variant="outlined"
                color="success"
                onClick={() => toggleContactPersonDialog(true)}>
            Kapcsolattartó
        </Button>
        <Grid style={{marginTop: '10px'}} container spacing={2}>
            {contactPersons.map(contact => <Grid key={contact?.id !== undefined ? contact.id : random(1, 100)} item xs={7} lg={6}>
                <Card sx={{maxWidth: 345}}>
                    <CardContent>
                        <Typography gutterBottom variant="h6" component="div">
                            {contact?.first_name} {contact?.last_name}
                        </Typography>
                        <Stack spacing={1}>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <IconButton color='success' onClick={() => callNumber(contact)}><PhoneIcon/></IconButton>
                                <Typography variant="body2" color="text.secondary">{contact?.phone ? contact?.phone : '-'}</Typography>
                            </Stack>
                            <Stack direction='row' spacing={2} alignItems='center'>
                                <IconButton color='success' onClick={() => sendMail(contact)}><EmailIcon/></IconButton>
                                <Typography variant="body2" color="text.secondary">{contact?.email ? contact?.email : '-'}</Typography>
                            </Stack>
                        </Stack>
                    </CardContent>
                    <CardActions>
                        <Button size="small" variant="outlined" color="success" onClick={() => {
                            setCurrent(contact.id);
                            toggleEditContactPersonDialog(true)
                        }}>Szerkesztés</Button>
                        <Button size="small" variant="outlined" color="error" onClick={() => {
                            setCurrent(contact.id);
                            toggleDeleteContactPersonDialog(true)
                        }}>Törlés</Button>
                    </CardActions>
                </Card>
            </Grid>)}
        </Grid>

        <DinoDialog
            title="Új kapcsolattartó"
            open={isContactPersonDialogOpen}
            handleClose={() => toggleContactPersonDialog(false)}
            actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                <Button size='large' variant='outlined' onClick={() => toggleContactPersonDialog(false)}>Mégsem</Button>
                <Button size='large' variant='outlined' color='success' onClick={() => {
                    handleNewContactPerson()
                    toggleContactPersonDialog(false)
                }}>Hozzáadás</Button>
            </Stack>}>
            <ContactPersonData reference={newContactPersonForm}/>
        </DinoDialog>

        <DinoDialog
            title="Kapcsolattartó szerkesztése"
            open={isEditContactPersonDialogOpen}
            handleClose={() => toggleEditContactPersonDialog(false)}
            actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                <Button size='large' variant='outlined' onClick={() => toggleEditContactPersonDialog(false)}>Mégsem</Button>
                <Button size='large' variant='outlined' color='success' onClick={() => {
                    handleEditContactPerson()
                    toggleEditContactPersonDialog(false)
                }}>Hozzáadás</Button>
            </Stack>}>
            <ContactPersonData reference={newContactPersonForm} contactPerson={getContactPerson(current)}/>
        </DinoDialog>

        <DinoDialog
            handleClose={() => toggleDeleteContactPersonDialog(false)}
            actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                <Button size='large' variant='outlined' onClick={() => toggleDeleteContactPersonDialog(false)}>Mégsem</Button>
                <Button size='large' variant='contained' color='error' onClick={() => {
                    toggleDeleteContactPersonDialog(false)
                    deleteContactPerson()
                }}>Törlés</Button>
            </Stack>}
            open={isDeleteContactPersonDialogOpen}
            title={`Biztosan törölni akarja: ${getContactPerson(current)?.first_name} ${getContactPerson(current)?.last_name}?`}
            subtitle='Minden kapcsolódó adat elvész.'/>
    </>;
}