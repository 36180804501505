import React, {useContext, useEffect, useRef, useState} from "react";
import BasicSpeedDial from "../../components/BasicSpeedDial";
import {DatePicker, LocalizationProvider} from "@mui/lab";
import {Button, Stack, TextField} from "@mui/material";
import AuthContext from "../../context/AuthContext";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import DailyData from "./DailyData";
import useCallDataApi from "../../hooks/data";
import {GridActionsCellItem} from "@mui/x-data-grid";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ConnectionData from "../orders/ConnectionData";
import {useSnackbar} from "notistack";
import locale from 'date-fns/locale/en-GB'
import MopedIcon from '@mui/icons-material/Moped';
import {format} from "date-fns";
import DeliveryPeriodData from "./DeliverPeriodData";
import {getFormData} from "../../utils/FormUtils";
import PersistentDrawerLeft from "../../components/NewDrawer";
import Loading from "../../components/Loading";
import DinoDialog from "../../components/Dialog";
import DataGridWrapper from "../../components/DataGridWrapper";
import {createMap, createMapKey} from "../../utils/NumberUtils";


const Daily = () => {
    const [orders, setOrders] = useState([])

    const [courierPDFDialog, toggleCourierPDFDialog] = useState(false)
    const [isCourierOrderDialogOpen, toggleCourierOrderDialog] = useState(false);
    const [isDeliveryPeriodDialogOpen, toggleDeliveryPeriodDialog] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [connections, setConnections] = useState({list: [], obj: {}})
    const [tourOffices, setTourOffices] = useState({list: [], obj: {}});
    const [tourGuides, setTourGuides] = useState({list: [], obj: {}});
    const [couriers, setCouriers] = useState([]);
    const [current, setCurrent] = useState(0);
    const [loading, setLoading] = useState(true);

    const {getData: getConnections, updateData: editConnection} = useCallDataApi("courier-order");
    const {updateData: editOrder, postData, postFile, getData: getOrders} = useCallDataApi("orders");
    const {getData: getTourOffices} = useCallDataApi("tour-offices");
    const {getData: getTourGuides} = useCallDataApi("tour-guides");
    const {getData: getCouriers} = useCallDataApi("couriers")

    const connectionForm = useRef(null);
    const deliveryForm = useRef(null);
    const courierForm = useRef(null);

    const {enqueueSnackbar} = useSnackbar();
    const {permissions} = useContext(AuthContext);

    const getConnection = (id) => connections.find(connection => connection.order === id)

    const getCurrentOrder = (id) => orders.find(order => order.id === id)

    useEffect(() => {
        let rd
        try {
            rd = format(selectedDate, 'yyyy-MM-dd')
            setLoading(true)
        } catch (e) {
            return
        }
        postData('daily', {date: rd})
            .then(o => setOrders(o))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedDate])

    useEffect(() => {
        const getData = async () => {
            const [c, g, o, co] = await Promise.all([
                getConnections(),
                getTourGuides(),
                getTourOffices(),
                getCouriers()
            ])
            if (c) setConnections({list: c, obj: createMapKey(c, 'order')})
            if (g) setTourGuides({list: g, obj: createMap(g)})
            if (o) setTourOffices({list: o, obj: createMap(o)})
            if (co) setCouriers(co)
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isSelectedDate = (date) => date === format(selectedDate, 'yyyy-MM-dd')

    const handleSetCourier = () => {
        let formData = getFormData(courierForm.current)
        getPDFForCourier({date: format(new Date(selectedDate), 'yyyy-MM-dd'), ...formData})
    }

    // Change the courier for the delivery or the return | input: {order: current, ...formData}
    const changeConnection = (data) => {
        const id = connections.obj[current].id;
        editConnection(id, data).then(editedConnection => {
            const editedConnections = connections.list.map(connection => {
                if (id === connection.id) return {...connection, ...editedConnection}
                return connection;
            });
            setConnections({list: editedConnections, obj: {...connections.obj, [current]: editedConnection}})
            setOrders(orders)
            enqueueSnackbar('Szerkesztve!', {variant: 'success'})
        })
    }

    const changeDeliveryPeriod = (data) => {
        editOrder(current, data).then(editedOrder => {
            const editedOrders = orders.map(order => {
                if (current === order.id) return {...order, ...editedOrder}
                return order;
            });
            setOrders(editedOrders)
            enqueueSnackbar('Szerkesztve!', {variant: 'success'})
        })
    }

    const handleSetDeliveryPeriod = () => {
        let formData = getFormData(deliveryForm.current)
        changeDeliveryPeriod({order: current, ...formData})
    }

    const handleSetCourierForOrder = () => {
        let formData = getFormData(connectionForm.current)
        changeConnection({order: current, ...formData})
    }

    const getPDFForCourier = (data) => {
        postFile('delivery_pdf', data, 'pdf', `${data.date}-${data.courier}`).then(d => console.log(d))
        // getPDF(data).then(d => console.log(d))
    }

    const renderWay = (params) => {
        const delivery_d = params.row['actual_delivery_date'];
        const return_d = params.row['actual_return_date'];
        if (isSelectedDate(delivery_d) && isSelectedDate(return_d)) {
            return <>
                <ArrowCircleRightIcon/><ArrowCircleLeftIcon/>
            </>
        }
        if (isSelectedDate(delivery_d)) return <ArrowCircleRightIcon/>
        if (isSelectedDate(return_d)) return <ArrowCircleLeftIcon/>
    }

    const columns = [
        {
            field: "manage",
            headerName: "Kezelés",
            sortable: false,
            type: "actions",
            getActions: (params) => [
                (permissions.includes('change_ordercourierconnection') && !params.row["closed"] ?
                    <GridActionsCellItem
                        icon={<ManageAccountsIcon/>}
                        onClick={() => {
                            setCurrent(params.row["id"])
                            toggleCourierOrderDialog(true)
                        }}
                        label="Futár beállítása"
                        showInMenu={true}
                    /> : <></>),
                <GridActionsCellItem
                    icon={<AvTimerIcon/>}
                    onClick={() => {
                        setCurrent(params.row["id"])
                        toggleDeliveryPeriodDialog(true)
                    }}
                    label="Kiszállítási idő beállítása"
                    showInMenu={true}
                />
            ],
        },
        {
            field: "way", headerName: "Irány", width: 100,
            renderCell: renderWay,
            valueGetter: (params) => isSelectedDate(params.row['actual_delivery_date']) ? 'Delivery' : 'Return'
        },
        {field: "delivery_courier_name", headerName: "Kiszállító futár", width: 140, valueGetter: p => {
                const conn = connections.obj[p.row.id]
                if (conn && conn?.delivery_courier) return couriers.find(c => c.id === conn.delivery_courier)?.name
                return ''
            }},
        {field: "return_courier_name", headerName: "Visszaszállító futár", width: 140, valueGetter: p => {
                const conn = connections.obj[p.row.id]
                if (conn && conn?.return_courier) return couriers.find(c => c.id === conn.return_courier)?.name
                return ''
            }},
        {field: "delivery_period", headerName: "Oda mikor", width: 50},
        {field: "return_period", headerName: "Vissza mikor", width: 50},
        {field: "order_number", headerName: "Rendelés szám", width: 140},
        {field: "comment", headerName: "Megjegyzés", width: 130},
        {field: "internal_comment", headerName: "Belsős megjegyzés", width: 130},
        {field: "order_status", headerName: "Állapot", width: 130},
        {field: "tour_type", headerName: "Program", width: 110},
        {field: "tour_office", headerName: "Megrendelő", width: 110,valueGetter: (params) => {
                const tg = tourOffices.obj[params.row.tour_office]
                if (tg) return tg?.name
                return ''
            }},
        {field: "tour_code", headerName: "Megnevezés", width: 110},
        {field: "tour_guide", headerName: "Csoportkísérő", width: 110, valueGetter: (params) => {
                const tg = tourGuides.obj[params.row.tour_guide]
                if (tg) return `${tg?.last_name} ${tg?.first_name}`
                return ''
            }},
        {field: "delivery_date", type: "date", headerName: "Kiszállítás dátum", width: 200},
        {
            field: "delivery_time",
            type: "string",
            headerName: "Kiszállítás idő",
            width: 200,
            renderCell: (params) => (params.row['delivery_time']?.slice(0, 5))
        },
        {field: "days_before", headerName: "Előtte", width: 110},
        {field: "delivery_type", headerName: "Kiszállítás típusa", width: 110},
        {field: "delivery_address", headerName: "Kiszállítási cím", width: 250},
        {field: "delivery_format", headerName: "Delivery format", width: 110},
        {field: "return_date", type: "date", headerName: "Vissza dátum", width: 200},
        {
            field: "return_time",
            type: "string",
            headerName: "Vissza idő",
            width: 200,
            renderCell: (params) => (params.row['return_time']?.slice(0, 5))
        },
        {field: "days_after", headerName: "Később", width: 110},
        {field: "return_type", headerName: "Visszaszállítás típusa", width: 110},
        {field: "return_address", headerName: "Vissza cím", width: 250},
        {field: "returned_rx", headerName: "Visszérkezett RX", width: 110},
        {field: "returned_tx", headerName: "Visszaérkezett TX", width: 110},
        {
            field: "color",
            headerName: "Color",
            width: 110,
            cellClassName: (params) => ('color-' + params.row['color'])
        },
        {field: "order_rx", headerName: "Rendelt RX", width: 110},
        {field: "order_tx", headerName: "Rendelt TX", width: 110},
        {field: "sent_rx", headerName: "Elküldött RX", width: 110},
        {field: "sent_tx", headerName: "Elküldött TX", width: 110},
        {field: "invoice", headerName: "Számla típusa", width: 110},
        {field: "invoice_number", headerName: "Számla azonosító", width: 110},
        {field: "currency", headerName: "Pénznem", width: 110},
        {field: "DHL_delivery_number", headerName: "Oda fuvarlevélszám", width: 110},
        {field: "DHL_return_number", headerName: "Vissza fuvarlevélszám", width: 110},
        {field: "DHL_order", headerName: "Oda felvételazonosító", width: 110},
        {field: "DHL_order_back", headerName: "Vissza felvételazonosító", width: 110},
        {field: "closed", headerName: "Lezárva", type: "boolean", width: 140}
    ]

    const speedDialActions = [{
        icon: (
            <ContactPageIcon
                onClick={() => (
                    toggleCourierPDFDialog(true)
                )}
            />
        ),
        name: "PDF a futárnak",
    }, {
        icon: (
            <SummarizeIcon
                onClick={() => postFile(
                    'daily_pdf',
                    {date: format(new Date(selectedDate), 'yyyy-MM-dd')},
                    'pdf',
                    format(new Date(selectedDate), 'yyyy-MM-dd'))
                }/>
        ),
        name: "PDF dátumhoz",
    }, {
        icon: (
            <MopedIcon
                onClick={() => postFile(
                    'daily_hand_pdf',
                    {date: format(new Date(selectedDate), 'yyyy-MM-dd')},
                    'pdf',
                    format(new Date(selectedDate), 'yyyy-MM-dd'))
                }/>
        ),
        name: "PDF csak kézbe",
    }];

    return (
        <div style={{height: "100vh"}}>
            <PersistentDrawerLeft title="Kiszállítások">
                <Stack spacing={2} direction='row' marginBottom={2}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                        <DatePicker
                            label="Date"
                            value={selectedDate}
                            mask="____-__-__"
                            inputFormat="yyyy-MM-dd"
                            onChange={newValue => setSelectedDate(newValue)}
                            renderInput={(params) => <TextField id="date" name="date" {...params} />}
                        />
                    </LocalizationProvider>
                </Stack>
                <DataGridWrapper name='daily' rows={orders} columns={columns}/>
                <Loading isLoading={loading}/>

            </PersistentDrawerLeft>
            {orders.length !== 0 && <BasicSpeedDial actions={speedDialActions}/>}

            {/*<AlertDialog*/}
            {/*    onNo={() => (toggleDeleteTourOperatorDialog(false))}*/}
            {/*    onYes={() => {*/}
            {/*        toggleDeleteTourOperatorDialog(false)*/}
            {/*        deleteTourOperator(currentlyDeleted)*/}
            {/*    }*/}
            {/*    }*/}
            {/*    open={isDeleteTourOperatorDialogOpen}*/}
            {/*    title={`Are you sure you want to delete ${getTourGuide(currentlyDeleted)?.name}?`}*/}
            {/*    content={'All things associated with it will be lost.'}/>*/}

            <DinoDialog
                title="PDF generálása futárnak"
                open={courierPDFDialog}
                handleClose={() => (toggleCourierPDFDialog(false))}
                actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                    <Button size='large' variant='outlined' onClick={() => toggleCourierPDFDialog(false)}>Mégsem</Button>
                    <Button size='large' variant='outlined' color='success' onClick={() => {
                        handleSetCourier()
                        toggleCourierPDFDialog(false)
                    }}>Generálás</Button>
                </Stack>}>
                <DailyData reference={courierForm}/>
            </DinoDialog>

            <DinoDialog
                title="Futár beállítása"
                open={isCourierOrderDialogOpen}
                handleClose={() => toggleCourierOrderDialog(false)}
                actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                    <Button size='large' variant='outlined' onClick={() => toggleCourierOrderDialog(false)}>Mégsem</Button>
                    <Button size='large' variant='outlined' color='success' onClick={() => {
                        handleSetCourierForOrder()
                        toggleCourierOrderDialog(false)
                    }}>Beállítás</Button>
                </Stack>}>
                <ConnectionData
                    reference={connectionForm}
                    connection={connections.obj[current]}/>
            </DinoDialog>

            <DinoDialog
                title="Kiszállítási idő beállítása"
                open={isDeliveryPeriodDialogOpen}
                handleClose={() => toggleDeliveryPeriodDialog(false)}
                actions={<Stack direction='row' spacing={2} marginRight={2} marginBottom={2}>
                    <Button size='large' variant='outlined' onClick={() => toggleDeliveryPeriodDialog(false)}>Mégsem</Button>
                    <Button size='large' variant='outlined' color='success' onClick={() => {
                        handleSetDeliveryPeriod()
                        toggleDeliveryPeriodDialog(false)
                    }}>Beállítás</Button>
                </Stack>}>
                <DeliveryPeriodData reference={deliveryForm} order={getCurrentOrder(current)}/>
            </DinoDialog>

        </div>
    );
};

export default Daily;
