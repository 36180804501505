const thousandSeparator = (num) => {
    if (num === undefined) return num
    if (num === null) return num
    if (num === '') return num
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const createMap = (list) => {
    const res = {}
    list.forEach(o => res[o.id] = o)
    return res
}

const createMapKey = (list, key) => {
    const res = {}
    list.forEach(o => res[o[key]] = o)
    return res
}

export {thousandSeparator, createMap, createMapKey}