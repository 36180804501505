import {InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import useCallDataApi from "../../hooks/data";
import {useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import QueryBuilder from "./QueryBuilder";

export default function ReportData({reference, report, queryBuilderRef, queryBuilderExcludeRef}) {
    const {getData} = useCallDataApi('report')
    const [contentTypes, setContentTypes] = useState([]);
    const [selectedModel, setSelectedModel] = useState(report?.model || ``)

    const handleChange = (event) => {
        setSelectedModel(event.target.value);
    };

    useEffect(() => {
        getData('get_models').then(d => setContentTypes(d))
    }, [])

    return <form ref={reference}>
        <Stack spacing={2} marginTop={2}>
            <TextField required id="name" name="name" label="Mi legyen a neve?" fullWidth
                       defaultValue={report?.name || ''}/>
            {!report && <FormControl fullWidth>
                <InputLabel id="content-type-select-label">Miből szűrjön?</InputLabel>
                <Select
                    labelId="content-type-select-label"
                    id="content-type-select"
                    name='model'
                    value={selectedModel}
                    label="Adatbázis model"
                    required
                    onChange={handleChange}
                >
                    {
                        Array.isArray(contentTypes) && contentTypes.length > 0 ?
                            contentTypes.map((c) => (
                                <MenuItem key={c?.id} value={c?.id}>{c?.name}</MenuItem>
                            )) :
                            // DEFAULT
                            <MenuItem disabled={true} value=''>No valid options...</MenuItem>
                    }
                </Select>
            </FormControl>}
            {report && <Typography variant='p'>Szűrési feltételek</Typography>}
            {report && <QueryBuilder report={report} ref={queryBuilderRef}/>}
            {report && <Typography variant='p'>Ezeket hagyja ki</Typography>}
            {report && <QueryBuilder report={report} exclude ref={queryBuilderExcludeRef}/>}
        </Stack>
    </form>;
}