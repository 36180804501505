import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const ReportBarChart = ({name, labels, datasets}) => {
    const data = {
        labels: labels,
        datasets: datasets
    };
    const options = {
        plugins: {
            title: {
                display: true,
                text: name,
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };
    return <Bar options={options} data={data}/>

}

export default ReportBarChart