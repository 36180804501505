import * as React from 'react';
import {
    DataGridPro,
    LicenseInfo,
    huHU,
    GridToolbar,
} from '@mui/x-data-grid-pro';
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import {
    gridFilteredSortedRowIdsSelector,
    GridToolbarColumnsButton,
    GridToolbarContainer, GridToolbarExport,
    GridToolbarFilterButton, gridVisibleColumnFieldsSelector,
    useGridApiContext
} from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import EMobiledataIcon from "@mui/icons-material/EMobiledata";
import * as XLSX from "xlsx";
import useCallDataApi from "../hooks/data";


const DinoDataGrid = ({rows, columns, name, onRowClick}) => {
    const [pageSize, setPageSize] = useState(50);
    const [visibility, setVisibility] = useState({})
    const {postData} = useCallDataApi('table-order')

    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY)

    useEffect(() => {
        postData('get_visibility', {name: name}).then(r => {
            if (r) setVisibility(r)
            else setVisibility({})
        })
    }, [])

    const CustomToolbar = () => {
        const apiRef = useGridApiContext();

        return (
            <GridToolbarContainer>
                <GridToolbarColumnsButton/>
                <GridToolbarFilterButton/>
                <Button
                    // className={classes.toolbar}
                    onClick={() => {
                        exportJS(getData(apiRef))
                    }}
                    startIcon={<EMobiledataIcon/>}
                >EXCEL
                </Button>
                <GridToolbarExport/>
            </GridToolbarContainer>
        );
    }

    const getData = (apiRef) => {
        const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

        return filteredSortedRowIds.map((id) => {
            const row = {};
            try {
                visibleColumnsField.forEach((field) => {
                    row[field] = apiRef.current.getCellParams(id, field).value;
                });
            } catch (e) {
                console.log(e)
            }
            return row;
        });

    };

    const exportJS = (data) => {

        const fields = Object.keys(data[0]);

        const wb = XLSX.utils.book_new(); // book
        const ws = XLSX.utils.json_to_sheet(data, {header: fields}); // sheet

        XLSX.utils.book_append_sheet(wb, ws, "exported");
        XLSX.writeFile(wb, "exported.xlsx");
    };

    const data = {
        rows: rows,
        columns: columns,
        rowLength: 10,
        maxColumns: 10,
    };

    return <Box sx={{height: '70vh', width: '100%'}}>
        <DataGridPro
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[30, 50, 100]}
            pagination
            {...data}
            components={{Toolbar: CustomToolbar}}
            disableSelectionOnClick
            density="compact"
            localeText={huHU.components.MuiDataGrid.defaultProps.localeText}
            onRowClick={(param) => {
                if (onRowClick) onRowClick(param.row)
            }}
            onColumnOrderChange={(c, e, d) => {
                const {columns} = d.api.exportState()
                postData('change_order', {name: name, order: columns.orderedFields}).then(r => console.log(r))
            }}
            onColumnVisibilityModelChange={(c, e) => {
                setVisibility(c)
                postData('change_visibility', {name: name, visibility: c}).then(r => console.log(r))
            }}
            columnVisibilityModel={visibility}
        />
    </Box>
}

export default DinoDataGrid