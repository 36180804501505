import React, {useContext, useState} from "react";
import AuthContext from "../context/AuthContext";
import {Button, Card, IconButton, InputAdornment, TextField} from "@mui/material";
import logo from "../assets/Tourguide logo.png"
import bg from "../assets/tgsbg.webp"
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

const LoginPage = () => {
    const {loginUser} = useContext(AuthContext);
    const [passwordVisible, setPasswordVisible] = useState(false)
    return (
        <div style={{width: '100vw', height: '100vh', background: `url(${bg})`, backgroundRepeat: 'no-repeat', backgroundSize: "cover", backgroundPosition: 'center'}}>
            <Card className="login">
                <img src={logo} alt="Logo" style={{
                    width: "30%",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto"
                }}/>
                <form className="form" onSubmit={loginUser}>
                    <TextField
                        className="inp"
                        label="Felhasználónév"
                        id="username"
                        name="username"
                        formControlProps={{
                            fullWidth: true,
                        }}
                        type="text"
                        margin="dense"
                    />

                    <TextField className="inp"
                               label="Jelszó"
                               id="password"
                               name="password"
                               margin="dense"
                               formControlProps={{
                                   fullWidth: true,
                               }} type={passwordVisible ? "text" : "password"} InputProps={{
                        endAdornment: <InputAdornment position="end"><IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                            edge="end"
                        >
                            {passwordVisible ? <VisibilityOffRoundedIcon /> : <VisibilityRoundedIcon />}
                        </IconButton>
                        </InputAdornment>
                    }}/>

                    <Button color="primary" variant="outlined" type="submit" className="form__custom-button">
                        Bejelentkezés
                    </Button>
                </form>
            </Card>
        </div>
    );
};

export default LoginPage;
