import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {ModeProvider} from "./context/ModeContext";
import {SnackbarProvider} from 'notistack';

ReactDOM.render(
    <React.StrictMode>
        <SnackbarProvider>
            <ModeProvider>
                <App/>
            </ModeProvider>
        </SnackbarProvider>
    </React.StrictMode>,
    document.getElementById('root')
);


