import {
    Card,
    CardActions,
    CardContent,
    Grid,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    Typography
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import React, {useContext, useEffect, useRef, useState} from "react";
import BasicSpeedDial from "../../components/BasicSpeedDial";
import AuthContext from "../../context/AuthContext";
import DinoDialog from "../../components/Dialog";
import useCallDataApi from "../../hooks/data";
import ReportData from "./ReportData";
import Button from "@mui/material/Button";
import {getFormData} from "../../utils/FormUtils";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import PersistentDrawerLeft from "../../components/NewDrawer";
import Loading from "../../components/Loading";
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

const Reports = () => {
    const {
        getData: fetchReports,
        postData: newReport,
        updateData: updateReport,
        deleteData: deleteReport
    } = useCallDataApi('report')
    const {permissions} = useContext(AuthContext)
    const [loading, setLoading] = useState(true)
    const [current, setCurrent] = useState(1)
    const [isNewReportDialogOpen, setNewReportDialogOpen] = useState(false)
    const [isEditReportDialogOpen, setEditReportDialogOpen] = useState(false)
    const [isDeleteReportDialogOpen, setDeleteReportDialogOpen] = useState(false)
    const [reports, setReports] = useState([])
    const {enqueueSnackbar} = useSnackbar()
    const history = useHistory()

    const reportForm = useRef(null)
    const queryBuilderRef = useRef(null)
    const queryBuilderExcludeRef = useRef(null)

    const findReport = (id) => {
        return reports.find(r => r.id === id)
    }

    useEffect(() => {
        const getData = async () => {
            const [r] = await Promise.all([
                fetchReports(),
            ])
            if (r) setReports(r)
        }
        getData()
            .catch(r => console.log(r))
            .finally(() => setLoading(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const createReport = () => {
        const formData = getFormData(reportForm.current)
        newReport('', formData).then(n => {
            setReports([...reports, n])
            enqueueSnackbar('Report added!', {variant: 'success'})
        })
    }

    const copyReport = (id) => {
        newReport('copy_report', {report_id: id}).then(n => {
            setReports([...reports, n])
            enqueueSnackbar('Report added!', {variant: 'success'})
        })
    }

    const editReport = () => {
        const formData = getFormData(reportForm.current)
        const newVar = {
            ...formData,
            filter_json: queryBuilderRef.current.get(),
            exclude_json: queryBuilderExcludeRef.current.get()
        };
        updateReport(current, newVar).then(editedReport => {
            const editedReportList = reports.map(report => {
                if (current === report.id) return {...report, ...editedReport}
                return report;
            });
            setReports(editedReportList)
            enqueueSnackbar('Report edited!', {variant: 'success'})
        })
    }

    const destroyReport = () => {
        deleteReport(current).then(() => {
            setReports(reports.filter(r => r.id !== current))
            enqueueSnackbar('Report deleted', {variant: 'success'})
        })
    }

    return <div style={{minHeight: "100vh"}}>
        <PersistentDrawerLeft title="Riportok">
            <Grid container spacing={2}>
                {reports?.sort((a, b) => a.id - b.id)?.map((r, i) => <Grid key={`report_${i}`} item xs={12} sm={4} md={3} lg={2}>
                    <Card sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                        <CardContent>
                            <Stack spacing={1} direction='row' justifyContent='space-between' alignItems='flex-start'>
                                <Stack>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {r?.name}
                                        {/*{r?.model_name}:*/}
                                    </Typography>
                                    {/*<Typography gutterBottom variant="body" component="div">*/}
                                    {/*    {r?.model_name}*/}
                                    {/*</Typography>*/}
                                </Stack>

                                <PopupState variant="popover" popupId="demo-popup-menu">
                                    {(popupState) => (
                                        <React.Fragment>
                                            <IconButton variant="contained" {...bindTrigger(popupState)}>
                                                <MoreVertRoundedIcon/>
                                            </IconButton>
                                            <Menu {...bindMenu(popupState)}>
                                                {/*<MenuItem onClick={popupState.close}>Profile</MenuItem>*/}
                                                <MenuItem onClick={() => {
                                                    setCurrent(r?.id)
                                                    popupState.close()
                                                    setEditReportDialogOpen(true)
                                                }}><ListItemIcon>
                                                    <EditRoundedIcon fontSize="small" />
                                                </ListItemIcon>Szerkesztés</MenuItem>
                                                <MenuItem onClick={() => {
                                                    setCurrent(r?.id)
                                                    popupState.close()
                                                    setDeleteReportDialogOpen(true)
                                                }}><ListItemIcon>
                                                    <DeleteRoundedIcon fontSize="small" />
                                                </ListItemIcon>Törlés</MenuItem>
                                                <MenuItem onClick={() => {
                                                    popupState.close()
                                                    copyReport(r?.id)
                                                }}><ListItemIcon>
                                                    <AddCircleOutlineRoundedIcon fontSize="small" />
                                                </ListItemIcon>Másolat készítése</MenuItem>
                                            </Menu>
                                        </React.Fragment>
                                    )}
                                </PopupState>
                            </Stack>
                        </CardContent>
                        <CardActions>
                            <Button size="medium" variant='contained' color='success'
                                    onClick={() => history.push(`report/${r?.id}`)}>Megnyitás</Button>
                            {/*<IconButton onClick={}>*/}
                            {/*    <EditRoundedIcon/>*/}
                            {/*</IconButton>*/}
                            {/*<IconButton onClick={} color='error'>*/}
                            {/*    <DeleteRoundedIcon/>*/}
                            {/*</IconButton>*/}
                        </CardActions>
                    </Card>
                </Grid>)}
            </Grid>

            <Loading isLoading={loading}/>

            {permissions.includes('add_report') && <BasicSpeedDial onClick={() => setNewReportDialogOpen(true)}/>}

            <DinoDialog title='Riport hozzáadása'
                        subtitle='Riport hozzáadása'
                        open={isNewReportDialogOpen}
                        handleClose={() => setNewReportDialogOpen(false)}
                        actions={<>
                            <Button variant='outlined' style={{padding: 15}}
                                    onClick={() => setNewReportDialogOpen(false)}>mégsem</Button>
                            <Button variant='contained' style={{padding: 15}} onClick={() => {
                                setNewReportDialogOpen(false)
                                createReport()
                            }} endIcon={<EditIcon/>}>Szerkesztés</Button>
                        </>}>
                <ReportData reference={reportForm} queryBuilderRef={queryBuilderRef}
                            queryBuilderExcludeRef={queryBuilderExcludeRef}/>
            </DinoDialog>

            <DinoDialog title='Riport szerkesztése'
                        subtitle='Riport szerkesztése'
                        open={isEditReportDialogOpen}
                        handleClose={() => setEditReportDialogOpen(false)}
                        actions={<>
                            <Button variant='outlined' style={{padding: 15}}
                                    onClick={() => setEditReportDialogOpen(false)}>mégsem</Button>
                            <Button variant='contained' style={{padding: 15}} onClick={() => {
                                setEditReportDialogOpen(false)
                                editReport()
                            }} endIcon={<EditIcon/>}>Szerkesztés</Button>
                        </>}>
                <ReportData reference={reportForm} report={findReport(current)} queryBuilderRef={queryBuilderRef}
                            queryBuilderExcludeRef={queryBuilderExcludeRef}/>
            </DinoDialog>

            <DinoDialog title='Riport törlése'
                        subtitle='Riport törlése'
                        open={isDeleteReportDialogOpen}
                        handleClose={() => setDeleteReportDialogOpen(false)}
                        actions={<>
                            <Button variant='outlined' style={{padding: 15}}
                                    onClick={() => setDeleteReportDialogOpen(false)}>mégsem</Button>
                            <Button variant='contained' color='error' style={{padding: 15}} onClick={() => {
                                setDeleteReportDialogOpen(false)
                                destroyReport()
                            }} endIcon={<DeleteIcon/>}>Törlés</Button>
                        </>}/>

        </PersistentDrawerLeft>
    </div>
}

export default Reports