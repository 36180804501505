import {Stack, TextField} from "@mui/material";

export default function TourGuideData({reference, tourOperator}) {

    return <form ref={reference}>
        <Stack spacing={2} paddingTop={1}>
            <TextField required id="first_name" name="first_name" label="Keresztnév" defaultValue={tourOperator?.first_name || ''}/>
            <TextField required id="last_name" name="last_name" label="Vezetéknév" defaultValue={tourOperator?.last_name || ''}/>
            <TextField id="phone" name="phone" label="Telefonszám" defaultValue={tourOperator?.phone || ''}/>
            <TextField id="email" name="email" label="Email" type="email" defaultValue={tourOperator?.email || ''}/>
            <TextField id="tourguide_type" name="tourguide_type" label="Típus" defaultValue={tourOperator?.tourguide_type || ''}/>
        </Stack>
    </form>;
}