import {Stack, TextField} from "@mui/material";
import React, {useEffect, useState} from "react";
import useCallDataApi from "../../hooks/data";
import Loading from "../../components/Loading";

const StickerData = ({reference, sticker}) => {
    const {postData: postSticker,} = useCallDataApi("sticker");
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({
        comment: ''
    })

    useEffect(() => {
        if (sticker) {
            console.log('current ' + sticker)
            postSticker('get_by_order', {order_id: sticker})
                .then(s => {
                    setData(s)
                    console.log(s)
                })
                .finally(() => setLoading(false))
        } else { setLoading(false) }
    }, [])

    return <form ref={reference}>
        <Stack spacing={2} marginTop={1}>
            <TextField onChange={e => setData(p => ({comment: e.target.value}))} multiline rows={4} id="comment"
                       name="nullable-comment" label="Megjegyzés" value={data?.comment}/>
        </Stack>
        <Loading isLoading={loading}/>
    </form>
}

export default StickerData